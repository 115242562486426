export default {
    methods: {
        addLecture(moduleId, themeId, studyFormId) {
            this.modal.root_id = moduleId
            this.modal.parent_id = themeId
            this.modal.study_form_id = studyFormId
            $("#popup_lecture").modal("show")
        },
        editLecture(moduleId, item) {
            this.modal = _.merge(this.modal, _.cloneDeep(item))
            this.modal.root_id = moduleId
            this.modal.parent_id = item.study_theme_id
            this.modal.isNew = false
            $('#popup_lecture').modal('show')
        },
        deleteLecture(theme, lectureId) {
            if (confirm('Удалить лекцию?')) {
                axios.delete(`${this.lectureUrl}/${lectureId}`)
                    .then(({status}) => {
                        if (status == 200) {
                            let i = _.findIndex(theme.lectures, ['id', lectureId])
                            theme.lectures.splice(i, 1);
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    })
            }
        },
        saveLecture() {
            this.validateErrors = {}

            let fd = new FormData($("#popup_lecture form").get(0))

            axios.post(this.lectureUrl, fd)
                .then(({data}) => {
                    let currentModule = this.studyPlan.modules.find(item => item.id == this.modal.root_id)
                    let currentTheme = currentModule.study_themes.find(item => item.id == this.modal.parent_id)
                    this.modal.id = data.id
                    this.modal.study_theme_id = data.study_theme_id
                    currentTheme.lectures.push(_.cloneDeep(this.modal))

                    $('#popup_lecture').modal('hide')
                })
                .catch(error => {
                    console.log(error);
                    this.validateErrors = error.response.data.errors
                })
        },
        updateLecture() {
            this.validateErrors = {}

            let fd = new FormData($("#popup_lecture form").get(0))

            axios.post(`${this.lectureUrl}/${this.modal.id}`, fd)
                .then(() => {
                    let currentModule = this.studyPlan.modules.find(item => item.id == this.modal.root_id)
                    let currentTheme = currentModule.study_themes.find(item => item.id == this.modal.parent_id)
                    let currentLecture = currentTheme.lectures.find(item => item.id == this.modal.id)

                    _.merge(currentLecture, this.modal)

                    $('#popup_lecture').modal('hide')
                })
                .catch(error => {
                    console.log(error);
                    this.validateErrors = error.response.data.errors
                })
        }
    }
}
