export default {
    methods: {
        saveModule() {
            this.validateErrors = {}

            let fd = new FormData($("#popup_module form").get(0))

            axios.post(this.studyPlanUrl, fd)
                .then(({data}) => {
                    this.modal.id = data.id
                    this.modal.study_themes = []
                    this.studyPlan.modules.push(_.cloneDeep(this.modal))

                    $('#popup_module').modal('hide')
                })
                .catch(error => {
                    this.validateErrors = error.response.data.errors
                })
        },
        updateModule() {
            this.validateErrors = {}

            let fd = new FormData($("#popup_module form").get(0))

            axios.post(`${this.studyPlanUrl}/${this.modal.id}`, fd)
                .then(() => {
                    let currentModule = this.studyPlan.modules.find(item => item.id == this.modal.id)
                    _.merge(currentModule, this.modal)

                    $('#popup_module').modal('hide')
                })
                .catch(error => {
                    this.validateErrors = error.response.data.errors
                })
        },
        deleteModule(id) {
            if (confirm('Удалить?')) {
                axios.delete(`${this.studyPlanUrl}/${id}`)
                    .then(({data}) => {
                        if (data) {
                            let i = this.studyPlan.modules.findIndex(item => item.id == id)
                            this.studyPlan.modules.splice(i, 1);
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    })
            }
        },
        editModule(item) {
            this.modal = _.merge(this.modal, _.cloneDeep(item))
            this.modal.isNew = false
            $('#popup_module').modal('show')
        }
    }
}
