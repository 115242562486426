$(() => {
    $("#del_scan_file").click(() => {
        axios.delete(location.href + "/delete-scan-file")
            .then(({ data }) => {
                if (data) location.reload()
            })
    })

    // another reason on general-characteristics page
    $("#reason_15").click(function () {
        if ($(this).is(":checked")) {
            $("#reason_another_text").show()
        } else {
            $("#reason_another_text").hide()
        }
    })

    setTimeout(() => {
        if ($("#reason_15").is(":checked")) {
            $("#reason_another_text").show()
        } else {
            $("#reason_another_text").hide()
        }
    })

    $("#add_program").click(function (e) {
        e.preventDefault()

        let text = $(this).text()
        $(this).text('Создание...');

        axios.post($(this).data('link'))
            .then(({data}) => {
                $(this).text(text);
                location.href = `${$(this).data('link')}/${data.id}`
            })
    })

    // delete prorgam
    $(".delete-program").click(function (e) {
        e.preventDefault()

        axios.delete($(this).data('link'))
            .then(() => {
                location.reload()
            })
    })
})