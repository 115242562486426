export default {
    methods: {
        addGroup(course) {
            axios.post(this.groupUrl, {course_id: course.id})
                .then(({status, data}) => {
                    if (status = 201) {
                        data.study_processes = []
                        course.groups.push(data)
                    }
                })
                .catch(error => {
                    console.log(error);
                })
        },
        deleteGroup(course, groupId) {
            if (confirm('Удалить группу?')) {
                axios.delete(`${this.groupUrl}/${groupId}`)
                    .then(({status}) => {
                        if (status == 200) {
                            let i = _.findIndex(course.groups, ['id', groupId])
                            course.groups.splice(i, 1);
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    })
            }
        }
    }
}
