<template>
    <div>
        <input type="hidden" :class="{'is-invalid': isInvalidField('professional_standarts')}" name="professional_standarts" v-model.number="professionalStandartsFilled">

        <div class="table-responsive mb-2" v-if="professionalStandarts && professionalStandarts.length">
            <table class="table table-bordered m-0">
                <thead class="table-info">
                    <tr>
                        <th scope="col" style="min-width: 280px;">«Наименование выбранного профессионального стандарта (одного или нескольких), обобщенная трудовая функция (ОТФ) и (или) трудовая функция (ТФ)»</th>
                        <th scope="col" style="min-width: 200px;">«Уровень квалификации ОТФ и (или) ТФ»</th>
                        <th scope="col" class="px-0"></th>
                    </tr>
                </thead>

                <tbody>
                    <tr v-for="profStandart in professionalStandarts">
                        <td>{{ getProfessionalStandartType(profStandart) }}</td>
                        <td>{{ profStandart.level }}</td>
                        <td class="align-middle text-center px-0">
                            <button type="button" class="btn text-muted" @click="editStandart(profStandart)">
                                <i class="bi bi-pencil-fill"></i>
                            </button>
                            <button type="button" class="btn text-danger" @click="deleteStandart(profStandart.id)">
                                <i class="bi bi-trash-fill"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="invalid-feedback mb-2" role="alert">
            <div v-for="error in validateErrors.professional_standarts"><strong>{{ error }}</strong></div>
        </div>

        <button type="button" class="btn btn-primary mt-1" @click="addStandart">Добавить стандарт</button>

        <!-- Modal Standarts -->
        <div class="modal fade" id="popup_prof_standart" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <form>
                        <input type="hidden" name="_method" value="PUT" key="standarts_input" v-if="!modal.isNew">
                        <input type="hidden" name="id" v-model="modal.id">

                        <div :class="[{'is-invalid': isInvalidField('type_standart_id')}, 'form-check', 'form-group']" v-for="typeStandart in typeStandarts">
                            <input class="form-check-input" type="radio" name="type_standart_id" :id="'radio' + typeStandart.id" :value="typeStandart.id" v-model="modal.type_standart_id">
                            <label class="form-check-label" :for="'radio' + typeStandart.id">
                                {{ typeStandart.name }}
                            </label>
                        </div>

                        <div class="invalid-feedback" role="alert">
                            <div v-for="error in validateErrors.type_standart_id"><strong>{{ error }}</strong></div>
                        </div>

                        <div class="form-group" v-if="isAnotherProfessionalStandartType(modal.type_standart_id)">
                            <textarea name="another_type" :class="[{'is-invalid': isInvalidField('another_type')}, 'form-control']" rows="3" v-model="modal.another_type" style="margin-top: -0.5rem;"></textarea>
                            <div class="invalid-feedback" role="alert">
                                <div v-for="error in validateErrors.another_type"><strong>{{ error }}</strong></div>
                            </div>
                        </div>

                        <div class="form-group mb-0">
                            <label>Уровень квалификации ОТФ и (или) ТФ</label>
                            <textarea name="level" :class="[{'is-invalid': isInvalidField('level')}, 'form-control']" rows="3" v-model="modal.level"></textarea>
                            <div class="invalid-feedback" role="alert">
                                <div v-for="error in validateErrors.level"><strong>{{ error }}</strong></div>
                            </div>
                        </div>
                    </form>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" @click="modal.isNew ? saveStandart() : updateStandart()">Сохранить</button>
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Закрыть</button>
                </div>
                </div>
            </div>
        </div>
        <!-- End Modal Standarts -->
    </div>
</template>

<script>
    import validator from './mixins/validator'

    export default {
        props: [
            'professionalStandartsCollection',
            'typeStandarts',
            'professionalStandartUrl',
            'errors'
        ],
        mixins: [validator],
        data() {
            return {
                professionalStandarts: this.professionalStandartsCollection,
                modal: {
                    isNew: true,
                    id: '',
                    type_standart_id: '',
                    another_type: '',
                    level: ''
                }
            }
        },
        computed: {
            professionalStandartsFilled() {
                return Boolean(this.professionalStandarts.length)
            }
        },
        methods: {
            addStandart() {
                $('#popup_prof_standart').modal('show')
            },
            editStandart(item) {
                this.modal = _.merge(this.modal, _.cloneDeep(item))
                this.modal.isNew = false
                $('#popup_prof_standart').modal('show')
            },
            saveStandart() {
                this.validateErrors = {}

                let fd = new FormData($("#popup_prof_standart form").get(0))

                axios.post(this.professionalStandartUrl, fd)
                    .then(({data}) => {
                        if (data) {
                            this.modal.id = data.id
                            this.modal.type_standart_id = data.type_standart_id
                            this.professionalStandarts.push(_.cloneDeep(this.modal))
                        }

                        $('#popup_prof_standart').modal('hide')
                    })
                    .catch(error => {
                        console.log(error);
                        this.validateErrors = error.response.data.errors
                    })
            },
            updateStandart() {
                this.validateErrors = {}

                let fd = new FormData($("#popup_prof_standart form").get(0))

                axios.post(`${this.professionalStandartUrl}/${this.modal.id}`, fd)
                    .then(({data}) => {
                        if (data) {
                            let currentStandart = this.professionalStandarts.find(item => item.id == this.modal.id)

                            _.merge(currentStandart, this.modal)

                            $('#popup_prof_standart').modal('hide')
                        }
                    })
                    .catch(error => {
                        console.log(error);
                        this.validateErrors = error.response.data.errors
                    })
            },
            deleteStandart(id) {
                axios.delete(`${this.professionalStandartUrl}/${id}`)
                    .then(({data}) => {
                        if (data && confirm('Удалить?')) {
                            let i = _.findIndex(this.professionalStandarts, ['id', id])
                            this.professionalStandarts.splice(i, 1);
                        }
                    })
            },
            isAnotherProfessionalStandartType(id) {
                return id == 3
            },
            getProfessionalStandartType(item) {
                return this.isAnotherProfessionalStandartType(item.type_standart_id)
                    ? item.another_type
                    : this.typeStandarts.find(v => v.id == item.type_standart_id).name
            }
        },
        mounted() {
            $('body').on("hidden.bs.modal", () => {
                this.validateErrors = {}

                this.modal = {
                    isNew: true,
                    id: '',
                    type_standart_id: '',
                    another_type: '',
                    level: ''
                }
            })
        }
    }
</script>
