export default {
    methods: {
        addTheme(parentStudyPlanId) {
            this.modal.parent_id = parentStudyPlanId
            $("#popup_theme").modal("show")
        },
        saveTheme() {
            this.validateErrors = {}

            let fd = new FormData($("#popup_theme form").get(0))

            axios.post(this.studyThemeUrl, fd)
                .then(({data}) => {
                    let currentModule = this.studyPlan.modules.find(item => item.id == this.modal.parent_id)
                    this.modal.id = data.id
                    this.modal.study_plan_id = this.modal.parent_id
                    this.modal.lectures = []
                    this.modal.practices = []
                    this.modal.independents = []
                    this.modal.distants = []
                    currentModule.study_themes.push(_.cloneDeep(this.modal))

                    $('#popup_theme').modal('hide')
                })
                .catch(error => {
                    console.log(error);
                    this.validateErrors = error.response.data.errors
                })
        },
        updateTheme() {
            this.validateErrors = {}

            let fd = new FormData($("#popup_theme form").get(0))

            axios.post(`${this.studyThemeUrl}/${this.modal.id}`, fd)
                .then(() => {
                    let currentModule = this.studyPlan.modules.find(item => item.id == this.modal.parent_id)
                    let currentTheme = currentModule.study_themes.find(item => item.id == this.modal.id)

                    _.merge(currentTheme, this.modal)

                    $('#popup_theme').modal('hide')
                })
                .catch(error => {
                    console.log(error);
                    this.validateErrors = error.response.data.errors
                })
        },
        saveCompetences() {
            this.validateErrors = {}

            let fd = new FormData($("#popup_theme_competences form").get(0))

            axios.post(`${this.studyThemeUrl}/${this.modal.id}`, fd)
                .then(() => {
                    let currentModule = this.studyPlan.modules.find(item => item.id == this.modal.parent_id)
                    let currentTheme = currentModule.study_themes.find(item => item.id == this.modal.id)

                    currentTheme[this.modal.competences_name] = this.modal.competences

                    $('#popup_theme_competences').modal('hide')
                })
                .catch(error => {
                    console.log(error);
                    this.validateErrors = error.response.data.errors
                })
        },
        editTheme(item) {
            this.modal = _.merge(this.modal, _.cloneDeep(item));
            this.modal.parent_id = item.study_plan_id
            this.modal.isNew = false
            $('#popup_theme').modal('show')
        },
        editCompetences(item, competencesName) {
            this.modal = _.merge(this.modal, _.cloneDeep(item));
            this.modal.parent_id = item.study_plan_id
            this.modal.competences = item[competencesName]
            this.modal.competences_name = competencesName

            $('#popup_theme_competences').modal('show')
        },
        deleteTheme(currentModule, id) {
            if (confirm('Удалить?')) {
                axios.delete(`${this.studyThemeUrl}/${id}`)
                    .then(({data}) => {
                        if (data) {
                            let i = currentModule.study_themes.findIndex(item => item.id == id)
                            currentModule.study_themes.splice(i, 1);
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    })
            }
        }
    }
}
