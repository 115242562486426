export default {
    data() {
        return {
            validateErrors: Object.assign({}, this.errors)
        }
    },
    methods: {
        isInvalidField(name) {
            return this.validateErrors.hasOwnProperty(name)
        }
    }
}
