export default {
    methods: {
        addStudyProcess(courseId, groupId, timeTableTypeId) {
            this.modal.root_id = courseId
            this.modal.parent_id = groupId
            this.modal.timetable_type_id = timeTableTypeId
            $("#popup_process").modal("show")
        },
        editStudyProcess(courseId, item) {
            this.modal = _.merge(this.modal, _.cloneDeep(item))
            this.modal.root_id = courseId
            this.modal.parent_id = item.group_id
            this.modal.isNew = false
            $('#popup_process').modal('show')
        },
        saveStudyProcess() {
            this.validateErrors = {}

            let fd = new FormData($("#popup_process form").get(0))

            axios.post(this.studyProcessUrl, fd)
                .then(({data}) => {
                    let currentCourse = this.studyPlan.courses.find(item => item.id == this.modal.root_id)
                    let currentGroup = currentCourse.groups.find(item => item.id == this.modal.parent_id)
                    this.modal.id = data.id
                    this.modal.group_id = this.modal.parent_id
                    currentGroup.study_processes.push(_.cloneDeep(this.modal))

                    $('#popup_process').modal('hide')
                })
                .catch(error => {
                    console.log(error);
                    this.validateErrors = error.response.data.errors
                })
        },
        updateStudyProcess() {
            this.validateErrors = {}

            let fd = new FormData($("#popup_process form").get(0))

            axios.post(`${this.studyProcessUrl}/${this.modal.id}`, fd)
                .then(({data}) => {
                    let currentCourse = this.studyPlan.courses.find(item => item.id == this.modal.root_id)
                    let currentGroup = currentCourse.groups.find(item => item.id == this.modal.parent_id)
                    let currentProcess = currentGroup.study_processes.find(item => item.id == this.modal.id)

                    _.merge(currentProcess, this.modal)

                    $('#popup_process').modal('hide')
                })
                .catch(error => {
                    console.log(error);
                    this.validateErrors = error.response.data.errors
                })
        },
        deleteStudyProcess(group, studyProcessId) {
            if (confirm('Удалить группу?')) {
                axios.delete(`${this.studyProcessUrl}/${studyProcessId}`)
                    .then(({status}) => {
                        if (status == 200) {
                            let i = _.findIndex(group.study_processes, ['id', studyProcessId])
                            group.study_processes.splice(i, 1);
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    })
            }
        }
    }
}
