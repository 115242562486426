<template>
    <form id="expert-opinions-form">
        <div class="form-group row">
            <label for="files" class="col-lg-5 col-xl-4 col-form-label text-lg-right pr-lg-0">Прикрепите экспертное заключение</label>

            <div class="col-lg-7 col-xl-8">
                <div class="custom-file">
                    <input type="file" :class="[{'is-invalid': isInvalidField('files')}, 'form-control-file', 'custom-file-input']" id="files" name="files[]" multiple accept=".pdf,.ppt,.pptx,.doc,.docx,.zip,.rar,.jpeg,.jpg,.png,.bmp">
                    <label class="custom-file-label" for="files">Выберите файл (.pdf)</label>
                </div>

                <div class="invalid-feedback" role="alert">
                    <div v-for="error in validateErrors.files"><strong>{{ error }}</strong></div>
                </div>
            </div>
        </div>

        <div class="form-group row" v-if="expertOpinions && expertOpinions.length">
            <label class="col-lg-5 col-xl-4 col-form-label text-lg-right pr-lg-0">Прикрепленные файлы</label>

            <div class="col-lg-7 col-xl-8">
                <div class="btn-group" v-for="(expertOpinion, index) in expertOpinions" :class="{'mb-1': index < expertOpinions.length - 1}">
                    <a class="btn btn-outline-secondary text-left" :href="storageUrl + expertOpinion.path" download>
                        <div class="text-truncate" style="max-width: 208px;">{{ expertOpinion.original_name }}</span></div>
                    </a>
                    <button type="button" class="btn btn-outline-secondary" @click="deleteFile(expertOpinion.id)">
                        <i class="bi bi-trash-fill"></i>
                    </button>
                </div>
            </div>
        </div>

        <div class="form-group mb-0 row">
            <div class="offset-lg-5 offset-xl-4 col mt-2">
                <button type="submit" class="btn btn-lg btn-success col-md-6" @click="uploadFiles">Сохранить</button>
            </div>
        </div>
    </form>
</template>

<script>
    import validator from "./mixins/validator";

    export default {
        props: [
            'expertOpinionsCollection',
            'expertOpinionsUrl',
            'storageUrl'
        ],
        mixins: [validator],
        data() {
            return {
                expertOpinions: this.expertOpinionsCollection
            }
        },
        methods: {
            uploadFiles() {
                let fd = new FormData($("#expert-opinions-form").get(0))

                axios.post(this.expertOpinionsUrl, fd)
                    .then(({data}) => {
                        this.expertOpinions.push(...data)
                        location.reload()
                    })
                    .catch(error => {
                        console.log(error);
                        this.validateErrors = error.response.data.errors
                    })
            },
            deleteFile(id) {
                if (confirm('Удалить файл?')) {
                    axios.delete(`${this.expertOpinionsUrl}/${id}`)
                        .then(({status}) => {
                            if (status == 200) {
                                let i = _.findIndex(this.expertOpinions, ['id', id])
                                this.expertOpinions.splice(i, 1)
                                location.reload()
                            }
                        })
                        .catch(error => {
                            console.log(error);
                        })
                }
            }
        }
    }
</script>
