<template>
    <div>
        <input type="hidden" :class="{'is-invalid': isInvalidField('professional_competences')}" name="professional_competences" v-model.number="professionalCompetencesFilled">

        <div class="table-responsive mb-2" v-if="professionalCompetences && professionalCompetences.length">
            <table class="table table-bordered m-0">
                <thead class="table-info">
                    <tr>
                        <th scope="col">«Виды деятельности»</th>
                        <th scope="col">«Профессиональные компетенции»</th>
                        <th scope="col">«Практический опыт»</th>
                        <th scope="col">«Умения»</th>
                        <th scope="col">«Знания»</th>
                        <th scope="col" class="px-0"></th>
                    </tr>
                </thead>

                <tbody>
                    <tr v-for="profCompetence in professionalCompetences">
                        <td>{{ profCompetence.type }}</td>
                        <td>{{ profCompetence.professional_competences }}</td>
                        <td>{{ profCompetence.experience }}</td>
                        <td>{{ profCompetence.skills }}</td>
                        <td>{{ profCompetence.knowledge }}</td>
                        <td class="align-middle text-center px-0">
                            <button type="button" class="btn text-muted" @click="editCompetence(profCompetence)">
                                <i class="bi bi-pencil-fill"></i>
                            </button>
                            <button type="button" class="btn text-danger" @click="deleteCompetence(profCompetence.id)">
                                <i class="bi bi-trash-fill"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="invalid-feedback mb-2" v-if="validateErrors.professional_competences && validateErrors.professional_competences.length" role="alert">
            <div v-for="error in validateErrors.professional_competences"><strong>{{ error }}</strong></div>
        </div>

        <button type="button" class="btn btn-primary mt-1" @click="addCompetence" key="add_standart">Добавить компетенцию</button>

        <!-- Modal Competence -->
        <div class="modal fade" id="popup_prof_competence" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <form>
                        <input type="hidden" name="_method" value="PUT" key="competences_input" v-if="!modal.isNew">
                        <input type="hidden" name="id" v-model="modal.id">

                        <div class="form-group" >
                            <label>1. Вид деятельности</label>
                            <textarea name="type" :class="[{'is-invalid': isInvalidField('type')}, 'form-control']" rows="3" v-model="modal.type"></textarea>
                            <div class="invalid-feedback" role="alert">
                                <div v-for="error in validateErrors.type"><strong>{{ error }}</strong></div>
                            </div>
                        </div>

                        <div class="form-group" >
                            <label>2. Профессиональные компетенции</label>
                            <textarea name="professional_competences" :class="[{'is-invalid': isInvalidField('professional_competences')}, 'form-control']" rows="3" v-model="modal.professional_competences"></textarea>
                            <div class="invalid-feedback" role="alert">
                                <div v-for="error in validateErrors.professional_competences"><strong>{{ error }}</strong></div>
                            </div>
                        </div>
                        <div class="form-group" >
                            <label>3. Практический опыт</label>
                            <textarea name="experience" :class="[{'is-invalid': isInvalidField('experience')}, 'form-control']" rows="3" v-model="modal.experience"></textarea>
                            <div class="invalid-feedback" role="alert">
                                <div v-for="error in validateErrors.experience"><strong>{{ error }}</strong></div>
                            </div>
                        </div>
                        <div class="form-group" >
                            <label>4. Умения</label>
                            <textarea name="skills" :class="[{'is-invalid': isInvalidField('skills')}, 'form-control']" rows="3" v-model="modal.skills"></textarea>
                            <div class="invalid-feedback" role="alert">
                                <div v-for="error in validateErrors.skills"><strong>{{ error }}</strong></div>
                            </div>
                        </div>
                        <div class="form-group" >
                            <label>5. Знания</label>
                            <textarea name="knowledge" :class="[{'is-invalid': isInvalidField('knowledge')}, 'form-control']" rows="3" v-model="modal.knowledge"></textarea>
                            <div class="invalid-feedback" role="alert">
                                <div v-for="error in validateErrors.knowledge"><strong>{{ error }}</strong></div>
                            </div>
                        </div>
                    </form>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" @click="modal.isNew ? saveCompetence() : updateCompetence()">Сохранить</button>
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Закрыть</button>
                </div>
                </div>
            </div>
        </div>
        <!-- End Modal Competence -->
    </div>
</template>

<script>
    import validator from './mixins/validator'

    export default {
        props: [
            'professionalCompetencesCollection',
            'professionalCompetenceUrl',
            'errors'
        ],
        mixins: [validator],
        data() {
            return {
                professionalCompetences: this.professionalCompetencesCollection,
                modal: {
                    isNew: true,
                    id: '',
                    type: '',
                    professional_competences: '',
                    experience: '',
                    skills: '',
                    knowledge: ''
                }
            }
        },
        computed: {
            professionalCompetencesFilled() {
                return Boolean(this.professionalCompetences.length)
            }
        },
        methods: {
            addCompetence() {
                $('#popup_prof_competence').modal('show')
            },
            editCompetence(item) {
                this.modal = _.merge(this.modal, _.cloneDeep(item))
                this.modal.isNew = false
                $('#popup_prof_competence').modal('show')
            },
            saveCompetence() {
                this.validateErrors = {}

                let fd = new FormData($("#popup_prof_competence form").get(0))

                axios.post(this.professionalCompetenceUrl, fd)
                    .then(({data}) => {
                        if (data) {
                            this.modal.id = data.id
                            this.professionalCompetences.push(_.cloneDeep(this.modal))

                            $('#popup_prof_competence').modal('hide')
                        }
                    })
                    .catch(error => {
                        console.log(error);
                        this.validateErrors = error.response.data.errors
                    })
            },
            updateCompetence() {
                this.validateErrors = {}

                let fd = new FormData($("#popup_prof_competence form").get(0))

                axios.post(`${this.professionalCompetenceUrl}/${this.modal.id}`, fd)
                    .then(({data}) => {
                        if (data) {
                            let currentCompetence = this.professionalCompetences.find(item => item.id == this.modal.id)

                            _.merge(currentCompetence, this.modal)

                            $('#popup_prof_competence').modal('hide')
                        }
                    })
                    .catch(error => {
                        console.log(error);
                        this.validateErrors = error.response.data.errors
                    })
            },
            deleteCompetence(id) {
                axios.delete(`${this.professionalCompetenceUrl}/${id}`)
                    .then(({data}) => {
                        if (data && confirm('Удалить?')) {
                            let i = _.findIndex(this.professionalCompetences, ['id', id])
                            this.professionalCompetences.splice(i, 1);
                        }
                    })
            }
        },
        mounted() {
            $('body').on("hidden.bs.modal", () => {
                this.validateErrors = {}

                this.modal = {
                    isNew: true,
                    id: '',
                    type: '',
                    professional_competences: '',
                    experience: '',
                    skills: '',
                    knowledge: ''
                }
            })
        }
    }
</script>
