<template>
    <div>
        <div class="form-group mb-5">
            <h4>2.1. Учебный план дополнительной профессиональной программы повышения квалификации</h4>
            <div class="table-responsive mb-2">
                <table class="table table-bordered text-center m-0">
                    <thead class="table-info">
                        <tr>
                            <th class="align-middle text-nowrap" scope="col" rowspan="3">№ п/п</th>
                            <th class="align-middle text-nowrap" scope="col" rowspan="3">Модуль/Раздел</th>
                            <th class="align-middle" scope="col" rowspan="3">Наименование компонентов разделов (модулей) программы</th>
                            <th class="align-middle" scope="col" colspan="7">Трудоемкость (ак.&nbsp;часов)</th>
                            <th class="align-middle" scope="col" rowspan="3">Аттестация (промежуточная) и&nbsp;текущий контроль (по&nbsp;освоению лекционного материала)</th>
                            <th class="align-middle" scope="col" rowspan="3">Форма контроля</th>
                            <th class="align-middle px-0" scope="col" rowspan="3"></th>
                        </tr>
                        <tr>
                            <th class="align-middle" rowspan="2">Всего</th>
                            <th class="align-middle" colspan="3">Аудиторная работа</th>
                            <th class="align-middle" colspan="3">Дистанционная работа</th>
                        </tr>
                        <tr>
                            <th class="align-middle">Лекции</th>
                            <th class="align-middle">Практические и&nbsp;семинарские занятия</th>
                            <th class="align-middle">СРС</th>
                            <th class="align-middle">Лекции (online)</th>
                            <th class="align-middle">Практические и&nbsp;семинарские занятия (online)</th>
                            <th class="align-middle">СРС</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in studyPlan.modules" :key="index">
                            <td class="align-middle">{{ index + 1 }}</td>
                            <td class="align-middle">{{ getStudyPlanType(item.study_plan_type_id) }}</td>
                            <td class="align-middle">{{ item.name }}</td>
                            <td class="align-middle">{{ totalHoursInRow(item.hours) }}</td>
                            <td class="align-middle" v-for="(hour, key) in item.hours" :key="key">{{ hour }}</td>
                            <td class="align-middle">{{ getAttestationType(item) }}</td>
                            <td class="align-middle px-0">
                                <button type="button" class="btn text-muted" @click="editModule(item)">
                                    <i class="bi bi-pencil-fill"></i>
                                </button>
                                <button type="button" class="btn text-danger" @click="deleteModule(item.id)">
                                    <i class="bi bi-trash-fill"></i>
                                </button>
                            </td>
                        </tr>

                        <tr>
                            <td class="align-middle"></td>
                            <td class="align-middle"></td>
                            <td class="align-middle">Итоговая аттестация</td>
                            <td class="align-middle">{{ studyPlan.finalAttestation.hours }}</td>
                            <td class="align-middle"><i class="bi bi-dash"></i></td>
                            <td class="align-middle"><i class="bi bi-dash"></i></td>
                            <td class="align-middle"><i class="bi bi-dash"></i></td>
                            <td class="align-middle"><i class="bi bi-dash"></i></td>
                            <td class="align-middle"><i class="bi bi-dash"></i></td>
                            <td class="align-middle"><i class="bi bi-dash"></i></td>
                            <td class="align-middle">{{ studyPlan.finalAttestation.hours }}</td>
                            <td class="align-middle"><i class="bi bi-dash"></i></td>
                            <td class="align-middle px-0">
                                <button type="button" class="btn text-muted" @click="editFinalAttestation(studyPlan.finalAttestation)">
                                    <i class="bi bi-pencil-fill"></i>
                                </button>
                            </td>
                        </tr>

                        <tr>
                            <td class="align-middle"></td>
                            <td class="align-middle"></td>
                            <td class="align-middle">Итого</td>
                            <td class="align-middle">{{ totalHours() }}</td>
                            <td class="align-middle" v-for="key in Object.keys(modal.hours)">{{ totalHoursInColumn(key) }}</td>
                            <td class="align-middle"><i class="bi bi-dash"></i></td>
                            <td class="align-middle"></td>
                        </tr>

                    </tbody>
                </table>
            </div>

            <input type="hidden" :class="{'is-invalid': isInvalidField('modules')}" name="modules" v-model.number="modulesFilled">
            <div class="invalid-feedback mb-2" role="alert">
                <div v-for="error in validateErrors.modules"><strong>{{ error }}</strong></div>
            </div>

            <button type="button" class="btn btn-primary mt-1" data-toggle="modal" data-target="#popup_module">Добавить модуль/раздел</button>
        </div>

        <div class="form-group mb-5">
            <h4>2.2. Учебно-тематический план дополнительной профессиональной программы повышения квалификации</h4>
            <div class="table-responsive">
                <table class="table table-bordered text-center m-0">
                    <thead class="table-info">
                        <tr>
                            <th class="align-middle text-nowrap" scope="col" rowspan="2">№ п/п</th>
                            <th class="align-middle" scope="col" rowspan="2">Наименование разделов (модулей), название&nbsp;тем</th>
                            <th class="align-middle" scope="col" rowspan="2">Всего, часов трудоемкости</th>
                            <th class="align-middle" scope="col" rowspan="2">Всего, аудитор.&nbsp;часов</th>
                            <th class="align-middle" scope="col" colspan="3">Аудиторная работа</th>
                            <th class="align-middle" scope="col" colspan="3">Дистанционная работа</th>
                            <th class="align-middle" scope="col" rowspan="2">Аттестация и&nbsp;текущий контроль</th>
                            <th class="align-middle px-0" scope="col" rowspan="2"></th>
                        </tr>
                        <tr>
                            <th class="align-middle">Лекции</th>
                            <th class="align-middle">Практические и&nbsp;семинарские занятия</th>
                            <th class="align-middle">СРС</th>
                            <th class="align-middle">Лекции (online)</th>
                            <th class="align-middle">Практические и&nbsp;семинарские занятия (online)</th>
                            <th class="align-middle">СРС</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="(studyModule, index) in studyPlan.modules">
                            <tr>
                                <td class="align-middle">{{ index + 1 }}</td>
                                <td class="align-middle">{{ studyModule.name }}</td>
                                <td class="align-middle">{{ totalHoursInRow(studyModule.hours) }}</td>
                                <td class="align-middle">{{ totalAudithoryHours(studyModule.hours) }}</td>
                                <td class="align-middle" v-for="(hour, key) in studyModule.hours" :key="key">{{ hour }}</td>
                                <td class="align-middle px-0"></td>
                            </tr>
                            <tr v-for="(theme, subIndex) in studyModule.study_themes">
                                <td class="align-middle">{{ index + 1 }}.{{ subIndex + 1 }}</td>
                                <td class="align-middle">{{ theme.name }}</td>
                                <td class="align-middle">{{ totalHoursInRow(theme.hours) }}</td>
                                <td class="align-middle">{{ totalAudithoryHours(theme.hours) }}</td>
                                <td class="align-middle" v-for="(hour, key) in theme.hours" :key="'theme' + key">{{ hour }}</td>
                                <td class="align-middle px-0">
                                    <button type="button" class="btn text-muted" @click="editTheme(theme)">
                                        <i class="bi bi-pencil-fill"></i>
                                    </button>
                                    <button type="button" class="btn text-danger" @click="deleteTheme(studyModule, theme.id)">
                                        <i class="bi bi-trash-fill"></i>
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="12" class="text-left"><button type="button" class="btn btn-outline-secondary" @click="addTheme(studyModule.id)">Добавить тему</button></td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>

            <input type="hidden" :class="{'is-invalid': isInvalidField('themes')}" name="themes" v-model.number="themesFilled">
            <div class="invalid-feedback mt-2" role="alert">
                <div v-for="error in validateErrors.themes"><strong>{{ error }}</strong></div>
            </div>
        </div>

        <div class="form-group mb-5">
            <h4>2.3. Календарный учебный график дополнительной профессиональной программы повышения квалификации</h4>
            <button type="button" class="btn btn-primary mt-2" @click="addCourse">Добавить курс</button>

            <div id="accordionCourses">
                <div class="card mt-3" v-for="(course, courseIndex) in studyPlan.courses" :key="course.id">
                    <div class="card-header">
                        <h5 class="mb-0">
                            <div class="row">
                                <div class="col">
                                    <button type="button" class="btn btn-link text-decoration-none text-left pl-0" data-toggle="collapse" :data-target="'#collapseCourse' + course.id" aria-expanded="false">
                                        <span class="h5 m-0 mr-1">Курс {{ courseIndex + 1 }}</span>
                                        <i class="bi bi-chevron-down"></i>
                                    </button>
                                </div>
                                <div class="col-auto">
                                    <button type="button" class="btn text-danger px-0" @click="deleteCourse(course.id)">
                                        <i class="bi bi-trash-fill"></i>
                                    </button>
                                </div>
                            </div>
                        </h5>
                    </div>

                    <div :id="'collapseCourse' + course.id" class="collapse" data-parent="#accordionCourses">
                        <div class="card-body">
                            <button type="button" class="btn btn-primary" @click="addGroup(course)">Добавить Группу</button>
                            <div :id="'accordionGroups' + course.id">
                                <div class="card mt-3" v-for="(group, groupIndex) in course.groups" :key="group.id">
                                    <div class="card-header">
                                        <h5 class="mb-0">
                                            <div class="row">
                                                <div class="col">
                                                    <button type="button" class="btn btn-link text-decoration-none text-left pl-0" data-toggle="collapse" :data-target="'#collapseGroup' + group.id" aria-expanded="false">
                                                        <span class="h5 m-0 mr-1">Группа {{ groupIndex + 1 }}</span>
                                                        <i class="bi bi-chevron-down"></i>
                                                    </button>
                                                </div>
                                                <div class="col-auto">
                                                    <button type="button" class="btn text-danger px-0" @click="deleteGroup(course, group.id)">
                                                        <i class="bi bi-trash-fill"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </h5>
                                    </div>

                                    <div :id="'collapseGroup' + group.id" class="collapse" :data-parent="'#accordionGroups' + course.id">
                                        <div class="card-body">
                                            <div :class="{'mb-4': index < timetableTypes.length - 1}" v-for="(timeTableType, index) in timetableTypes">
                                                <h5>{{ timeTableType.name }}</h5>
                                                <div class="table-responsive mb-3">
                                                    <table class="table table-bordered text-center m-0">
                                                        <thead class="table-info">
                                                            <tr>
                                                                <th scope="col" class="align-middle">Наименование учебного процесса</th>
                                                                <th scope="col" class="align-middle">Сроки обучения</th>
                                                                <th scope="col" class="align-middle">Количество дней</th>
                                                                <th scope="col" class="align-middle px-0"></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="studyProcess in group.study_processes.filter(i => i.timetable_type_id == timeTableType.id)">
                                                                <td class="align-middle">{{ studyProcess.name }}</td>
                                                                <td class="align-middle">
                                                                    <span class="text-nowrap">{{ studyProcess.date_from }}</span>
                                                                    <span class="text-nowrap">{{ studyProcess.date_to }}</span>
                                                                </td>
                                                                <td class="align-middle">{{ studyProcess.count_days }}</td>
                                                                <td class="align-middle px-0">
                                                                    <button type="button" class="btn text-muted" @click="editStudyProcess(course.id, studyProcess)">
                                                                        <i class="bi bi-pencil-fill"></i>
                                                                    </button>
                                                                    <button type="button" class="btn text-danger" @click="deleteStudyProcess(group, studyProcess.id)">
                                                                        <i class="bi bi-trash-fill"></i>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td class="align-middle"></td>
                                                                <td class="align-middle">Итого</td>
                                                                <td class="align-middle">{{ totalDays(group.study_processes.filter(i => i.timetable_type_id == timeTableType.id)) }}</td>
                                                                <td class="align-middle px-0"></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <button type="button" class="btn btn-sm btn-outline-primary" @click="addStudyProcess(course.id, group.id, timeTableType.id)">Добавить учебный процесс</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="form-group mb-4" v-if="existLecturesClassroom || existPracticeClassroom || existIndependentClassroom || existLecturesDistant || existPracticeDistant || existIndependentDistant">
            <h4 class="mb-3">2.4. Рабочие программы</h4>
            <h5 class="mb-3 py-2 px-3 table-info text-uppercase" v-if="existLecturesClassroom || existPracticeClassroom || existIndependentClassroom"><b>Аудиторная работа</b></h5>

            <div class="mb-4" v-if="existLecturesClassroom">
                <h6 class="text-uppercase m-0"><b>Лекции</b></h6>
                <div id="accordionLecturesClassroom">
                    <div class="card mt-3" v-for="(studyModule, moduleIndex) in studyPlan.modules" :key="studyModule.id">
                        <template v-if="studyModule.hours.lectures_classroom_hours">
                            <div class="card-header">
                                <h5 class="mb-0">
                                    <button type="button" class="btn btn-link text-decoration-none text-left pl-0" data-toggle="collapse" :data-target="'#collapseStudyModuleClassroom' + studyModule.id" aria-expanded="false">
                                        <span class="h5 m-0 mr-1">{{ studyModule.name }}</span>
                                        <i class="bi bi-chevron-down"></i>
                                    </button>
                                </h5>
                            </div>

                            <div :id="'collapseStudyModuleClassroom' + studyModule.id" class="collapse" data-parent="#accordionLecturesClassroom">
                                <div class="card-body">
                                    <div class="table-responsive">
                                        <table class="table table-bordered m-0">
                                            <thead class="table-info">
                                                <tr>
                                                    <th scope="col" class="align-middle text-center">№</th>
                                                    <th scope="col" class="align-middle">Тема лекции</th>
                                                    <th scope="col" class="align-middle">Содержание лекционных занятий (выражается в понятиях и проблемах). Тезисный план лекции по теме</th>
                                                    <th scope="col" class="align-middle">Формируемые компетенции (указываются шифры компетенций) (из раздела п. 1.6)</th>
                                                    <th scope="col" class="align-middle px-0">Действие</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <template v-for="(theme, themeIndex) in studyModule.study_themes">
                                                    <template v-if="theme.hours.lectures_classroom_hours">
                                                        <tr>
                                                            <td class="text-center">{{ moduleIndex + 1 }}.{{ themeIndex + 1 }}</td>
                                                            <td colspan="2">{{ theme.name }}</td>
                                                            <td>{{ theme.lecture_classroom_competences }}</td>
                                                            <td class="align-middle text-center px-0">
                                                                <button type="button" class="btn text-muted" @click="editCompetences(theme, 'lecture_classroom_competences')">
                                                                    <i class="bi bi-pencil-fill"></i>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="5">
                                                                <button type="button" class="btn btn-sm btn-outline-primary" @click="addLecture(studyModule.id, theme.id, 1)">Добавить лекцию</button>
                                                            </td>
                                                        </tr>
                                                        <tr v-for="(lecture, lectureIndex) in theme.lectures.filter(lec => lec.study_form_id == 1)">
                                                            <td class="text-center">{{ moduleIndex + 1 }}.{{ themeIndex + 1 }}.{{ lectureIndex + 1 }}</td>
                                                            <td>{{ lecture.theme }}</td>
                                                            <td>{{ lecture.plan }}</td>
                                                            <td></td>
                                                            <td class="align-middle text-center px-0">
                                                                <button type="button" class="btn text-muted" @click="editLecture(studyModule.id, lecture)">
                                                                    <i class="bi bi-pencil-fill"></i>
                                                                </button>
                                                                <button type="button" class="btn text-danger" @click="deleteLecture(theme, lecture.id)">
                                                                    <i class="bi bi-trash-fill"></i>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    </template>
                                                </template>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>

            <div class="mb-4" v-if="existPracticeClassroom">
                <h6 class="text-uppercase m-0"><b>Практические и семинарские занятия</b></h6>
                <div id="accordionPracticesClassroom">
                    <div class="card mt-3" v-for="(studyModule, moduleIndex) in studyPlan.modules" :key="studyModule.id">
                        <template v-if="studyModule.hours.practice_classroom_hours">
                            <div class="card-header">
                                <h5 class="mb-0">
                                    <button type="button" class="btn btn-link text-decoration-none text-left pl-0" data-toggle="collapse" :data-target="'#collapsePracticeStudyModuleClassroom' + studyModule.id" aria-expanded="false">
                                        <span class="h5 m-0 mr-1">{{ studyModule.name }}</span>
                                        <i class="bi bi-chevron-down"></i>
                                    </button>
                                </h5>
                            </div>

                            <div :id="'collapsePracticeStudyModuleClassroom' + studyModule.id" class="collapse" data-parent="#accordionPracticesClassroom">
                                <div class="card-body">
                                    <div class="table-responsive">
                                        <table class="table table-bordered m-0">
                                            <thead class="table-info">
                                                <tr>
                                                    <th scope="col" class="align-middle text-center">№</th>
                                                    <th scope="col" class="align-middle">Содержание практических и семинарских занятий (план)</th>
                                                    <th scope="col" class="align-middle">Формируемые компетенции (указываются шифры компетенций) (из раздела п. 1.6)</th>
                                                    <th scope="col" class="align-middle px-0"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <template v-for="(theme, themeIndex) in studyModule.study_themes">
                                                    <template v-if="theme.hours.practice_classroom_hours">
                                                        <tr>
                                                            <td class="text-center">{{ moduleIndex + 1 }}.{{ themeIndex + 1 }}</td>
                                                            <td>{{ theme.name }}</td>
                                                            <td>{{ theme.practice_classroom_competences }}</td>
                                                            <td class="align-middle text-center px-0">
                                                                <button type="button" class="btn text-muted" @click="editCompetences(theme, 'practice_classroom_competences')">
                                                                    <i class="bi bi-pencil-fill"></i>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="4">
                                                                <button type="button" class="btn btn-sm btn-outline-primary" @click="addPractice(studyModule.id, theme.id, 1)">Добавить практические и семинарские занятия</button>
                                                            </td>
                                                        </tr>
                                                        <tr v-for="(practice, practiceIndex) in theme.practices.filter(p => p.study_form_id == 1)">
                                                            <td class="text-center">{{ moduleIndex + 1 }}.{{ themeIndex + 1 }}.{{ practiceIndex + 1 }}</td>
                                                            <td>{{ practice.plan }}</td>
                                                            <td></td>
                                                            <td class="align-middle text-center px-0">
                                                                <button type="button" class="btn text-muted" @click="editPractice(studyModule.id, practice)">
                                                                    <i class="bi bi-pencil-fill"></i>
                                                                </button>
                                                                <button type="button" class="btn text-danger" @click="deletePractice(theme, practice.id)">
                                                                    <i class="bi bi-trash-fill"></i>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    </template>
                                                </template>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>

            <div class="mb-4" v-if="existIndependentClassroom">
                <h6 class="text-uppercase m-0"><b>Самостоятельная учебная работа</b></h6>
                <div id="accordionIndependentsClassroom">
                    <div class="card mt-3" v-for="(studyModule, moduleIndex) in studyPlan.modules" :key="studyModule.id">
                        <template v-if="studyModule.hours.independent_classroom_hours">
                            <div class="card-header">
                                <h5 class="mb-0">
                                    <button type="button" class="btn btn-link text-decoration-none text-left pl-0" data-toggle="collapse" :data-target="'#collapseIndependentStudyModuleClassroom' + studyModule.id" aria-expanded="false">
                                        <span class="h5 m-0 mr-1">{{ studyModule.name }}</span>
                                        <i class="bi bi-chevron-down"></i>
                                    </button>
                                </h5>
                            </div>

                            <div :id="'collapseIndependentStudyModuleClassroom' + studyModule.id" class="collapse" data-parent="#accordionIndependentsClassroom">
                                <div class="card-body">
                                    <div class="table-responsive">
                                        <table class="table table-bordered m-0">
                                            <thead class="table-info">
                                                <tr>
                                                    <th scope="col" class="align-middle text-center">№</th>
                                                    <th scope="col" class="align-middle">Содержание самостоятельной работы</th>
                                                    <th scope="col" class="align-middle">Формируемые компетенции (указываются шифры компетенций) (из раздела п. 1.6)</th>
                                                    <th scope="col" class="align-middle px-0"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <template v-for="(theme, themeIndex) in studyModule.study_themes">
                                                    <template v-if="theme.hours.independent_classroom_hours">
                                                        <tr>
                                                            <td class="text-center">{{ moduleIndex + 1 }}.{{ themeIndex + 1 }}</td>
                                                            <td>{{ theme.name }}</td>
                                                            <td>{{ theme.independent_classroom_competences }}</td>
                                                            <td>
                                                                <button type="button" class="btn text-muted" @click="editCompetences(theme, 'independent_classroom_competences')">
                                                                    <i class="bi bi-pencil-fill"></i>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="4">
                                                                <button type="button" class="btn btn-primary" @click="addIndependent(studyModule.id, theme.id, 1)">Добавить самостоятельную работу</button>
                                                            </td>
                                                        </tr>
                                                        <tr v-for="(independent, independentIndex) in theme.independents.filter(i => i.study_form_id == 1)">
                                                            <td class="text-center">{{ moduleIndex + 1 }}.{{ themeIndex + 1 }}.{{ independentIndex + 1 }}</td>
                                                            <td>{{ independent.plan }}</td>
                                                            <td></td>
                                                            <td>
                                                                <button type="button" class="btn text-muted" @click="editIndependent(studyModule.id, independent)">
                                                                    <i class="bi bi-pencil-fill"></i>
                                                                </button>
                                                                <button type="button" class="btn text-danger" @click="deleteIndependent(theme, independent.id)">
                                                                    <i class="bi bi-trash-fill"></i>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    </template>
                                                </template>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>

            <h5 class="mt-4 mb-3 py-2 px-3 table-info text-uppercase" v-if="existLecturesDistant || existPracticeDistant || existIndependentDistant">Дистанционная работа</h5>

            <div class="mb-4" v-if="existLecturesDistant">
                <h6 class="text-uppercase m-0"><b>Лекции</b></h6>
                <div id="accordionLecturesDistant">
                    <div class="card mt-3" v-for="(studyModule, moduleIndex) in studyPlan.modules" :key="studyModule.id">
                        <template v-if="studyModule.hours.lectures_distant_hours">
                            <div class="card-header">
                                <h5 class="mb-0">
                                    <button type="button" class="btn btn-link text-decoration-none text-left pl-0" data-toggle="collapse" :data-target="'#collapseStudyModuleDistant' + studyModule.id" aria-expanded="false">
                                        <span class="h5 m-0 mr-1">{{ studyModule.name }}</span>
                                        <i class="bi bi-chevron-down"></i>
                                    </button>
                                </h5>
                            </div>

                            <div :id="'collapseStudyModuleDistant' + studyModule.id" class="collapse" data-parent="#accordionLecturesDistant">
                                <div class="card-body">
                                    <div class="table-responsive">
                                        <table class="table table-bordered m-0">
                                            <thead class="table-info">
                                                <tr>
                                                    <th scope="col" class="align-middle text-center">№</th>
                                                    <th scope="col" class="align-middle">Тема лекции</th>
                                                    <th scope="col" class="align-middle">Содержание лекционных занятий (выражается в понятиях и проблемах). Тезисный план лекции по теме</th>
                                                    <th scope="col" class="align-middle">Формируемые компетенции (указываются шифры компетенций) (из раздела п. 1.6)</th>
                                                    <th scope="col" class="align-middle px-0"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <template v-for="(theme, themeIndex) in studyModule.study_themes">
                                                    <template v-if="theme.hours.lectures_distant_hours">
                                                        <tr>
                                                            <td class="text-center">{{ moduleIndex + 1 }}.{{ themeIndex + 1 }}</td>
                                                            <td colspan="2">{{ theme.name }}</td>
                                                            <td>{{ theme.lecture_distant_competences }}</td>
                                                            <td class="align-middle text-center px-0">
                                                                <button type="button" class="btn text-muted" @click="editCompetences(theme, 'lecture_distant_competences')">
                                                                    <i class="bi bi-pencil-fill"></i>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="5">
                                                                <button type="button" class="btn btn-sm btn-outline-primary" @click="addLecture(studyModule.id, theme.id, 3)">Добавить лекцию</button>
                                                            </td>
                                                        </tr>
                                                        <tr v-for="(lecture, lectureIndex) in theme.lectures.filter(lec => lec.study_form_id == 3)">
                                                            <td class="text-center">{{ moduleIndex + 1 }}.{{ themeIndex + 1 }}.{{ lectureIndex + 1 }}</td>
                                                            <td>{{ lecture.theme }}</td>
                                                            <td>{{ lecture.plan }}</td>
                                                            <td></td>
                                                            <td class="align-middle text-center px-0">
                                                                <button type="button" class="btn text-muted" @click="editLecture(studyModule.id, lecture)">
                                                                    <i class="bi bi-pencil-fill"></i>
                                                                </button>
                                                                <button type="button" class="btn text-danger" @click="deleteLecture(theme, lecture.id)">
                                                                    <i class="bi bi-trash-fill"></i>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    </template>
                                                </template>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>

            <div class="mb-4" v-if="existPracticeDistant">
                <h6 class="text-uppercase m-0"><b>Практические и семинарские занятия</b></h6>
                <div id="accordionPracticesDistant">
                    <div class="card mt-3" v-for="(studyModule, moduleIndex) in studyPlan.modules" :key="studyModule.id">
                        <template v-if="studyModule.hours.practice_distant_hours">
                            <div class="card-header">
                                <h5 class="mb-0">
                                    <button type="button" class="btn btn-link text-decoration-none text-left pl-0" data-toggle="collapse" :data-target="'#collapsePracticeStudyModuleDistant' + studyModule.id" aria-expanded="false">
                                        <span class="h5 m-0 mr-1">{{ studyModule.name }}</span>
                                        <i class="bi bi-chevron-down"></i>
                                    </button>
                                </h5>
                            </div>

                            <div :id="'collapsePracticeStudyModuleDistant' + studyModule.id" class="collapse" data-parent="#accordionPracticesDistant">
                                <div class="card-body">
                                    <div class="table-responsive">
                                        <table class="table table-bordered m-0">
                                            <thead class="table-info">
                                                <tr>
                                                    <th scope="col" class="align-middle text-center">№</th>
                                                    <th scope="col" class="align-middle">Содержание практических и семинарских занятий (план)</th>
                                                    <th scope="col" class="align-middle">Формируемые компетенции (указываются шифры компетенций) (из раздела п. 1.6)</th>
                                                    <th scope="col" class="align-middle px-0"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <template v-for="(theme, themeIndex) in studyModule.study_themes">
                                                    <template v-if="theme.hours.practice_distant_hours">
                                                        <tr>
                                                            <td class="text-center">{{ moduleIndex + 1 }}.{{ themeIndex + 1 }}</td>
                                                            <td>{{ theme.name }}</td>
                                                            <td>{{ theme.practice_distant_competences }}</td>
                                                            <td class="align-middle text-center px-0">
                                                                <button type="button" class="btn text-muted" @click="editCompetences(theme, 'practice_distant_competences')">
                                                                    <i class="bi bi-pencil-fill"></i>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="4">
                                                                <button type="button" class="btn btn-sm btn-outline-primary" @click="addPractice(studyModule.id, theme.id, 3)">Добавить практические и семинарские занятия</button>
                                                            </td>
                                                        </tr>
                                                        <tr v-for="(practice, practiceIndex) in theme.practices.filter(p => p.study_form_id == 3)">
                                                            <td class="text-center">{{ moduleIndex + 1 }}.{{ themeIndex + 1 }}.{{ practiceIndex + 1 }}</td>
                                                            <td>{{ practice.plan }}</td>
                                                            <td></td>
                                                            <td class="align-middle text-center px-0">
                                                                <button type="button" class="btn text-muted" @click="editPractice(studyModule.id, practice)">
                                                                    <i class="bi bi-pencil-fill"></i>
                                                                </button>
                                                                <button type="button" class="btn text-danger" @click="deletePractice(theme, practice.id)">
                                                                    <i class="bi bi-trash-fill"></i>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    </template>
                                                </template>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>

            <div class="mb-4" v-if="existIndependentDistant">
                <h6 class="text-uppercase m-0"><b>Самостоятельная учебная работа</b></h6>
                <div id="accordionIndependentsDistant">
                    <div class="card" v-for="(studyModule, moduleIndex) in studyPlan.modules" :key="studyModule.id">
                        <template v-if="studyModule.hours.independent_distant_hours">
                            <div class="card-header">
                                <h5 class="mb-0">
                                    <button type="button" class="btn btn-link text-decoration-none text-left pl-0" data-toggle="collapse" :data-target="'#collapseIndependentStudyModuleDistant' + studyModule.id" aria-expanded="false">
                                        <span class="h5 m-0 mr-1">{{ studyModule.name }}</span>
                                        <i class="bi bi-chevron-down"></i>
                                    </button>
                                </h5>
                            </div>

                            <div :id="'collapseIndependentStudyModuleDistant' + studyModule.id" class="collapse" data-parent="#accordionIndependentsDistant">
                                <div class="card-body">
                                    <div class="table-responsive">
                                        <table class="table table-bordered m-0">
                                            <thead class="table-info">
                                                <tr>
                                                    <th scope="col" class="align-middle text-center">№</th>
                                                    <th scope="col" class="align-middle">Содержание самостоятельной работы</th>
                                                    <th scope="col" class="align-middle">Формируемые компетенции (указываются шифры компетенций) (из раздела п. 1.6)</th>
                                                    <th scope="col" class="align-middle px-0"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <template v-for="(theme, themeIndex) in studyModule.study_themes">
                                                    <template v-if="theme.hours.independent_distant_hours">
                                                        <tr>
                                                            <td class="text-center">{{ moduleIndex + 1 }}.{{ themeIndex + 1 }}</td>
                                                            <td>{{ theme.name }}</td>
                                                            <td>{{ theme.independent_distant_competences }}</td>
                                                            <td class="align-middle text-center px-0">
                                                                <button type="button" class="btn text-muted" @click="editCompetences(theme, 'independent_distant_competences')">
                                                                    <i class="bi bi-pencil-fill"></i>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="4">
                                                                <button type="button" class="btn btn-sm btn-outline-primary" @click="addIndependent(studyModule.id, theme.id, 3)">Добавить самостоятельную работу</button>
                                                            </td>
                                                        </tr>
                                                        <tr v-for="(independent, independentIndex) in theme.independents.filter(i => i.study_form_id == 3)">
                                                            <td class="text-center">{{ moduleIndex + 1 }}.{{ themeIndex + 1 }}.{{ independentIndex + 1 }}</td>
                                                            <td>{{ independent.plan }}</td>
                                                            <td></td>
                                                            <td class="align-middle text-center px-0">
                                                                <button type="button" class="btn text-muted" @click="editIndependent(studyModule.id, independent)">
                                                                    <i class="bi bi-pencil-fill"></i>
                                                                </button>
                                                                <button type="button" class="btn text-danger" @click="deleteIndependent(theme, independent.id)">
                                                                    <i class="bi bi-trash-fill"></i>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    </template>
                                                </template>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal Module -->
        <div class="modal fade" id="popup_module" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <form>
                        <input type="hidden" name="_method" value="PUT" key="module_input" v-if="!modal.isNew">
                        <input type="hidden" name="id" v-model="modal.id">
                        <input type="hidden" name="total_hours" :value="totalHoursInRow(modal.hours)">

                        <div class="form-group">
                            <label>1. Модуль/Раздел программы</label>
                            <select id="study_plan_type_id" name="study_plan_type_id" :class="[{'is-invalid': isInvalidField('study_plan_type_id')}, 'form-control']" v-model="modal.study_plan_type_id">
                                <option value="0" disabled>Не выбрано</option>
                                <option v-for="studyPlanType in studyPlanTypes" :key="studyPlanType.id" :value="studyPlanType.id">{{ studyPlanType.name }}</option>
                            </select>
                            <div class="invalid-feedback" role="alert">
                                <div v-for="error in validateErrors.study_plan_type_id"><strong>{{ error }}</strong></div>
                            </div>
                        </div>

                        <div class="form-group mb-4">
                            <label>Наименование модуля (раздела) программы</label>
                            <textarea name="name" :class="[{'is-invalid': isInvalidField('name')}, 'form-control']" rows="3" v-model="modal.name"></textarea>
                            <div class="invalid-feedback" role="alert">
                                <div v-for="error in validateErrors.name"><strong>{{ error }}</strong></div>
                            </div>
                        </div>

                        <h4 class="mb-3">Трудоемкость (ак.час)</h4>
                        <h6 class="mb-3 py-2 px-3 table-info text-uppercase">Аудиторная работа</h6>

                        <div class="form-group">
                            <label>2. Лекции</label>
                            <input name="lectures_classroom_hours" :class="[{'is-invalid': isInvalidField('lectures_classroom_hours')}, 'form-control']" type="number" min="0" v-model.number="modal.hours.lectures_classroom_hours">
                            <div class="invalid-feedback" role="alert">
                                <div v-for="error in validateErrors.lectures_classroom_hours"><strong>{{ error }}</strong></div>
                            </div>
                        </div>

                        <div class="form-group">
                            <label>3. Практические и семинарские занятия</label>
                            <input name="practice_classroom_hours" :class="[{'is-invalid': isInvalidField('practice_classroom_hours')}, 'form-control']" type="number" min="0" v-model.number="modal.hours.practice_classroom_hours">
                            <div class="invalid-feedback" role="alert">
                                <div v-for="error in validateErrors.practice_classroom_hours"><strong>{{ error }}</strong></div>
                            </div>
                        </div>

                        <div class="form-group mb-4">
                            <label>4. СРС</label>
                            <input name="independent_classroom_hours" :class="[{'is-invalid': isInvalidField('independent_classroom_hours')}, 'form-control']" type="number" min="0" v-model.number="modal.hours.independent_classroom_hours">
                            <div class="invalid-feedback" role="alert">
                                <div v-for="error in validateErrors.independent_classroom_hours"><strong>{{ error }}</strong></div>
                            </div>
                        </div>

                        <h6 class="mb-3 py-2 px-3 table-info text-uppercase">Дистанционная работа</h6>

                        <div class="form-group">
                            <label>5. Дистанционная работа</label>
                            <input name="lectures_distant_hours" :class="[{'is-invalid': isInvalidField('lectures_distant_hours')}, 'form-control']" type="number" min="0" v-model.number="modal.hours.lectures_distant_hours">
                            <div class="invalid-feedback" role="alert">
                                <div v-for="error in validateErrors.lectures_distant_hours"><strong>{{ error }}</strong></div>
                            </div>
                        </div>

                        <div class="form-group">
                            <label>6. Практические занятия (online)</label>
                            <input name="practice_distant_hours" :class="[{'is-invalid': isInvalidField('practice_distant_hours')}, 'form-control']" type="number" min="0" v-model.number="modal.hours.practice_distant_hours">
                            <div class="invalid-feedback" role="alert">
                                <div v-for="error in validateErrors.practice_distant_hours"><strong>{{ error }}</strong></div>
                            </div>
                        </div>

                        <div class="form-group">
                            <label>7. СРС</label>
                            <input name="independent_distant_hours" :class="[{'is-invalid': isInvalidField('independent_distant_hours')}, 'form-control']" type="number" min="0" v-model.number="modal.hours.independent_distant_hours">
                            <div class="invalid-feedback" role="alert">
                                <div v-for="error in validateErrors.independent_distant_hours"><strong>{{ error }}</strong></div>
                            </div>
                        </div>

                        <div class="form-group">
                            <label>8. Аттестация</label>
                            <input name="attestation_hours" :class="[{'is-invalid': isInvalidField('attestation_hours')}, 'form-control']" type="number" min="0" v-model.number="modal.hours.attestation_hours">
                            <div class="invalid-feedback" role="alert">
                                <div v-for="error in validateErrors.attestation_hours"><strong>{{ error }}</strong></div>
                            </div>
                        </div>

                        <div class="form-group">
                            <label>9. Форма аттестации</label>
                            <select name="attestation_type_id" :class="[{'is-invalid': isInvalidField('attestation_type_id')}, 'form-control']" v-model="modal.attestation_type_id">
                                <option value="0" disabled>Не выбрано</option>
                                <option v-for="attestationType in attestationTypes" :key="attestationType.id" :value="attestationType.id">{{ attestationType.name }}</option>
                            </select>
                            <div class="invalid-feedback" role="alert">
                                <div v-for="error in validateErrors.attestation_type_id"><strong>{{ error }}</strong></div>
                            </div>
                        </div>

                        <div class="form-group mb-0" v-if="isAnotherAttestationType(modal.attestation_type_id)">
                            <label>Форма аттестации</label>
                            <textarea name="attestation_another_type" :class="[{'is-invalid': isInvalidField('attestation_another_type')}, 'form-control']" rows="3" v-model="modal.attestation_another_type"></textarea>
                            <div class="invalid-feedback" role="alert">
                                <div v-for="error in validateErrors.attestation_another_type"><strong>{{ error }}</strong></div>
                            </div>
                        </div>
                    </form>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" @click="modal.isNew? saveModule() : updateModule()">Сохранить</button>
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Закрыть</button>
                </div>
                </div>
            </div>
        </div>
        <!-- End Modal Module -->

        <!-- Modal Attestation -->
        <div class="modal fade" id="popup_attestation" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">

                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <div class="modal-body">
                        <form>
                            <input type="hidden" name="_method" value="PUT" key="attestation_input">

                            <div class="form-group mb-0">
                                <label>Трудоемкость (ак.час)</label>
                                <input name="hours" :class="[{'is-invalid': isInvalidField('hours')}, 'form-control']" type="number" min="0" v-model.number="modal.finalAttestationHours">
                                <div class="invalid-feedback" role="alert">
                                    <div v-for="error in validateErrors.hours"><strong>{{ error }}</strong></div>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" @click="saveFinalAttestation">Обновить</button>
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Закрыть</button>
                    </div>

                </div>
            </div>
        </div>
        <!-- End Modal Attestation -->

        <!-- Modal Theme -->
        <div class="modal fade" id="popup_theme" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <form>
                        <input type="hidden" name="_method" value="PUT" key="theme_input" v-if="!modal.isNew">
                        <input type="hidden" name="id" v-model="modal.id">
                        <input type="hidden" name="study_plan_id" v-model="modal.parent_id">
                        <input type="hidden" name="total_hours" :value="totalHoursInRow(modal.hours)">

                        <div class="form-group mb-4">
                            <label>1. Наименование темы</label>
                            <textarea name="name" :class="[{'is-invalid': isInvalidField('name')}, 'form-control']" rows="3" v-model="modal.name"></textarea>
                            <div class="invalid-feedback" role="alert">
                                <div v-for="error in validateErrors.name"><strong>{{ error }}</strong></div>
                            </div>
                        </div>

                        <h4 class="mb-3">Трудоемкость (ак.час)</h4>
                        <h6 class="mb-3 py-2 px-3 table-info text-uppercase">Аудиторная работа</h6>

                        <div class="form-group">
                            <label>2. Лекции</label>
                            <input name="lectures_classroom_hours" :class="[{'is-invalid': isInvalidField('lectures_classroom_hours')}, 'form-control']" type="number" min="0" v-model.number="modal.hours.lectures_classroom_hours">
                            <div class="invalid-feedback" role="alert">
                                <div v-for="error in validateErrors.lectures_classroom_hours"><strong>{{ error }}</strong></div>
                            </div>
                        </div>

                        <div class="form-group">
                            <label>3. Практические и семинарские занятия</label>
                            <input name="practice_classroom_hours" :class="[{'is-invalid': isInvalidField('practice_classroom_hours')}, 'form-control']" type="number" min="0" v-model.number="modal.hours.practice_classroom_hours">
                            <div class="invalid-feedback" role="alert">
                                <div v-for="error in validateErrors.practice_classroom_hours"><strong>{{ error }}</strong></div>
                            </div>
                        </div>

                        <div class="form-group mb-4">
                            <label>4. СРС</label>
                            <input name="independent_classroom_hours" :class="[{'is-invalid': isInvalidField('independent_classroom_hours')}, 'form-control']" type="number" min="0" v-model.number="modal.hours.independent_classroom_hours">
                            <div class="invalid-feedback" role="alert">
                                <div v-for="error in validateErrors.independent_classroom_hours"><strong>{{ error }}</strong></div>
                            </div>
                        </div>

                        <h6 class="mb-3 py-2 px-3 table-info text-uppercase">Дистанционная работа</h6>

                        <div class="form-group">
                            <label>5. Лекции (online)</label>
                            <input name="lectures_distant_hours" :class="[{'is-invalid': isInvalidField('lectures_distant_hours')}, 'form-control']" type="number" min="0" v-model.number="modal.hours.lectures_distant_hours">
                            <div class="invalid-feedback" role="alert">
                                <div v-for="error in validateErrors.lectures_distant_hours"><strong>{{ error }}</strong></div>
                            </div>
                        </div>

                        <div class="form-group">
                            <label>6. Практические занятия (online)</label>
                            <input name="practice_distant_hours" :class="[{'is-invalid': isInvalidField('practice_distant_hours')}, 'form-control']" type="number" min="0" v-model.number="modal.hours.practice_distant_hours">
                            <div class="invalid-feedback" role="alert">
                                <div v-for="error in validateErrors.practice_distant_hours"><strong>{{ error }}</strong></div>
                            </div>
                        </div>

                        <div class="form-group">
                            <label>7. СРС</label>
                            <input name="independent_distant_hours" :class="[{'is-invalid': isInvalidField('independent_distant_hours')}, 'form-control']" type="number" min="0" v-model.number="modal.hours.independent_distant_hours">
                            <div class="invalid-feedback" role="alert">
                                <div v-for="error in validateErrors.independent_distant_hours"><strong>{{ error }}</strong></div>
                            </div>
                        </div>

                        <div class="form-group mb-0">
                            <label>8. Аттестация</label>
                            <input name="attestation_hours" :class="[{'is-invalid': isInvalidField('attestation_hours')}, 'form-control']" type="number" min="0" v-model.number="modal.hours.attestation_hours">
                            <div class="invalid-feedback" role="alert">
                                <div v-for="error in validateErrors.attestation_hours"><strong>{{ error }}</strong></div>
                            </div>
                        </div>
                    </form>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" @click="modal.isNew ? saveTheme() : updateTheme()">Сохранить</button>
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Закрыть</button>
                </div>
                </div>
            </div>
        </div>
        <!-- End Modal Theme -->

        <!-- Modal Process -->
        <div class="modal fade" id="popup_process" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <form>
                        <input type="hidden" name="_method" value="PUT" key="process_input" v-if="!modal.isNew">
                        <input type="hidden" name="id" v-model="modal.id">
                        <input type="hidden" name="group_id" v-model="modal.parent_id" v-if="modal.isNew">
                        <input type="hidden" name="timetable_type_id" v-model="modal.timetable_type_id" v-if="modal.isNew">

                        <div class="form-group">
                            <label>1. Наименование учебного процесса</label>
                            <textarea name="name" :class="[{'is-invalid': isInvalidField('name')}, 'form-control']" rows="3" v-model="modal.name"></textarea>
                            <div class="invalid-feedback" role="alert">
                                <div v-for="error in validateErrors.name"><strong>{{ error }}</strong></div>
                            </div>
                        </div>

                        <div class="form-group">
                            <label>2. Сроки обучения</label>
                            <div class="form-row">
                                <div claas="col">
                                    <input type="date" name="date_from" :class="[{'is-invalid': isInvalidField('date_from')}, 'form-control']" v-model="modal.date_from">
                                    <div class="invalid-feedback" role="alert">
                                        <div v-for="error in validateErrors.date_from"><strong>{{ error }}</strong></div>
                                    </div>
                                </div>
                                <div claas="col">
                                    <input type="date" name="date_to" :class="[{'is-invalid': isInvalidField('date_to')}, 'form-control']" v-model="modal.date_to">
                                    <div class="invalid-feedback" role="alert">
                                        <div v-for="error in validateErrors.date_to"><strong>{{ error }}</strong></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group mb-0">
                            <label>3. Количество дней</label>
                            <input name="count_days" :class="[{'is-invalid': isInvalidField('count_days')}, 'form-control']" type="number" min="0" v-model.number="modal.count_days">
                            <div class="invalid-feedback" role="alert">
                                <div v-for="error in validateErrors.count_days"><strong>{{ error }}</strong></div>
                            </div>
                        </div>
                    </form>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" @click="modal.isNew ? saveStudyProcess() : updateStudyProcess()">Сохранить</button>
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Закрыть</button>
                </div>
                </div>
            </div>
        </div>
        <!-- End Modal Process -->

        <!-- Modal Lectures -->
        <div class="modal fade" id="popup_lecture" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <form>
                        <input type="hidden" name="_method" value="PUT" key="lecture_input" v-if="!modal.isNew">
                        <input type="hidden" name="id" v-model="modal.id">
                        <input type="hidden" name="study_form_id" v-model="modal.study_form_id" v-if="modal.study_form_id">
                        <input type="hidden" name="study_theme_id" v-model="modal.parent_id" v-if="modal.isNew">

                        <div class="form-group">
                            <label>Тема Лекции</label>
                            <textarea name="theme" :class="[{'is-invalid': isInvalidField('theme')}, 'form-control']" rows="3" v-model="modal.theme"></textarea>
                            <div class="invalid-feedback" role="alert">
                                <div v-for="error in validateErrors.theme"><strong>{{ error }}</strong></div>
                            </div>
                        </div>

                        <div class="form-group mb-0">
                            <label>Содержание лекционных занятий (выражается в понятиях и проблемах). Тезисный план лекции по теме</label>
                            <textarea name="plan" :class="[{'is-invalid': isInvalidField('plan')}, 'form-control']" rows="3" v-model="modal.plan"></textarea>
                            <div class="invalid-feedback" role="alert">
                                <div v-for="error in validateErrors.plan"><strong>{{ error }}</strong></div>
                            </div>
                        </div>
                    </form>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" @click="modal.isNew ? saveLecture() : updateLecture()">Сохранить</button>
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Закрыть</button>
                </div>
                </div>
            </div>
        </div>
        <!-- End Modal Lectures -->

        <!-- Modal Practice -->
        <div class="modal fade" id="popup_practice" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <form>
                        <input type="hidden" name="_method" value="PUT" key="practice_input" v-if="!modal.isNew">
                        <input type="hidden" name="id" v-model="modal.id">
                        <input type="hidden" name="study_form_id" v-model="modal.study_form_id" v-if="modal.study_form_id">
                        <input type="hidden" name="study_theme_id" v-model="modal.parent_id" v-if="modal.isNew">

                        <div class="form-group mb-0">
                            <label>Содержание практических и семинарских занятий (план)</label>
                            <textarea name="plan" :class="[{'is-invalid': isInvalidField('plan')}, 'form-control']" rows="3" v-model="modal.plan"></textarea>
                            <div class="invalid-feedback" role="alert">
                                <div v-for="error in validateErrors.plan"><strong>{{ error }}</strong></div>
                            </div>
                        </div>
                    </form>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" @click="modal.isNew ? savePractice() : updatePractice()">Сохранить</button>
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Закрыть</button>
                </div>
                </div>
            </div>
        </div>
        <!-- End Modal Practice -->

        <!-- Modal Independent -->
        <div class="modal fade" id="popup_independent" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <form>
                        <input type="hidden" name="_method" value="PUT" key="independent_input" v-if="!modal.isNew">
                        <input type="hidden" name="id" v-model="modal.id">
                        <input type="hidden" name="study_form_id" v-model="modal.study_form_id" v-if="modal.study_form_id">
                        <input type="hidden" name="study_theme_id" v-model="modal.parent_id" v-if="modal.isNew">

                        <div class="form-group mb-0">
                            <label>Содержание самостоятельной работы</label>
                            <textarea name="plan" :class="[{'is-invalid': isInvalidField('plan')}, 'form-control']" rows="3" v-model="modal.plan"></textarea>
                            <div class="invalid-feedback" role="alert">
                                <div v-for="error in validateErrors.plan"><strong>{{ error }}</strong></div>
                            </div>
                        </div>
                    </form>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" @click="modal.isNew ? saveIndependent() : updateIndependent()">Сохранить</button>
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Закрыть</button>
                </div>
                </div>
            </div>
        </div>
        <!-- End Modal Independent -->

        <!-- Modal Competences -->
        <div class="modal fade" id="popup_theme_competences" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <form>
                        <input type="hidden" name="_method" value="PUT" key="competences_input">

                        <div class="form-group mb-0">
                            <label>Формируемые компетенции (указываются шифры компетенций) (из раздела п. 1.6)</label>
                            <textarea :name="modal.competences_name" :class="[{'is-invalid': isInvalidField(modal.competences_name)}, 'form-control']" rows="3" v-model="modal.competences"></textarea>
                            <div class="invalid-feedback" role="alert">
                                <div v-for="error in validateErrors[modal.competences_name]"><strong>{{ error }}</strong></div>
                            </div>
                        </div>
                    </form>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" @click="saveCompetences">Сохранить</button>
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Закрыть</button>
                </div>
                </div>
            </div>
        </div>
        <!-- End Modal Competences -->
    </div>
</template>

<script>
    import validator from "./mixins/validator"
    import moduleMethods from "./mixins/moduleMethods"
    import finalAttestationMethods from "./mixins/finalAttestationMethods"
    import themeMethods from "./mixins/themeMethods"
    import courseMethods from "./mixins/courseMethods"
    import groupsMethods from "./mixins/groupsMethods"
    import studyProcessMethods from "./mixins/studyProcessMethods"
    import lectureMethods from "./mixins/lectureMethods"
    import practiceMethods from "./mixins/practiceMethods"
    import independentMethods from "./mixins/independentMethods"

    export default {
        props: [
            'studyPlanTypes',
            'attestationTypes',
            'studyPlanCollection',
            'finalAttestation',
            'studyPlanUrl',
            'studyThemeUrl',
            'finalAttestationUrl',
            'courseCollection',
            'courseUrl',
            'groupUrl',
            'timetableTypes',
            'studyProcessUrl',
            'lectureUrl',
            'practiceUrl',
            'independentUrl',
            'errors'
        ],
        mixins: [
            validator,
            moduleMethods,
            finalAttestationMethods,
            themeMethods,
            courseMethods,
            groupsMethods,
            studyProcessMethods,
            lectureMethods,
            practiceMethods,
            independentMethods
        ],
        data() {
            return {
                studyPlan: {
                    modules: this.studyPlanCollection,
                    finalAttestation: this.finalAttestation,
                    courses: this.courseCollection
                },
                modal: {
                    isNew: true,
                    id: '',
                    root_id: '',
                    parent_id: '',
                    study_form_id: '',
                    study_plan_type_id: 0,
                    name: '',
                    entity: '',
                    entity_id: '',
                    entity_name: '',
                    hours: {
                        lectures_classroom_hours: '',
                        practice_classroom_hours: '',
                        independent_classroom_hours: '',
                        lectures_distant_hours: '',
                        practice_distant_hours: '',
                        independent_distant_hours: '',
                        attestation_hours: ''
                    },
                    attestation_type_id: 0,
                    attestation_another_type: '',
                    finalAttestationHours: '',
                    date_from: '',
                    date_to: '',
                    count_days: '',
                    timetable_type_id: '',
                    competences: '',
                    competences_name: '',
                    theme: '',
                    plan: ''
                }
            }
        },
        computed: {
            modulesFilled() {
                return Boolean(this.studyPlan.modules.length)
            },
            themesFilled() {
                return  this.modulesFilled && _.every(this.studyPlan.modules, item => Boolean(item.study_themes.length))
            },
            existLecturesClassroom() {
                return _.some(this.studyPlan.modules, item => item.hours.lectures_classroom_hours > 0)
            },
            existPracticeClassroom() {
                return _.some(this.studyPlan.modules, item => item.hours.practice_classroom_hours > 0)
            },
            existIndependentClassroom() {
                return _.some(this.studyPlan.modules, item => item.hours.independent_classroom_hours > 0)
            },
            existLecturesDistant() {
                return _.some(this.studyPlan.modules, item => item.hours.lectures_distant_hours > 0)
            },
            existPracticeDistant() {
                return _.some(this.studyPlan.modules, item => item.hours.practice_distant_hours > 0)
            },
            existIndependentDistant() {
                return _.some(this.studyPlan.modules, item => item.hours.independent_distant_hours > 0)
            },
        },
        methods: {
            getStudyPlanType(id) {
                return this.studyPlanTypes.find(v => v.id == id).name
            },
            getAttestationType(item) {
                return this.isAnotherAttestationType(item.attestation_type_id)
                    ? item.attestation_another_type
                    : this.attestationTypes.find(v => v.id == item.attestation_type_id).name
            },
            totalHoursInColumn(key) {
                let total = _.sumBy(this.studyPlan.modules, item => item.hours[key])

                if (key == 'attestation_hours') total += this.studyPlan.finalAttestation.hours

                return total
            },
            totalHoursInRow(hours) {
                return _.sum(_.values(hours))
            },
            totalAudithoryHours(hours) {
                return hours.lectures_classroom_hours
                + hours.practice_classroom_hours
                + hours.independent_classroom_hours
            },
            totalHours() {
                return _.sumBy(this.studyPlan.modules, item => this.totalHoursInRow(item.hours)) + Number(this.studyPlan.finalAttestation.hours)
            },
            totalDays(studyProcesses) {
                return _.sumBy(studyProcesses, 'count_days')
            },
            isAnotherAttestationType(id) {
                return id == 3
            }
        },
        mounted() {
            $('body').on("hidden.bs.modal", () => {
                this.validateErrors = {}

                this.modal = {
                    isNew: true,
                    id: '',
                    root_id: '',
                    parent_id: '',
                    study_form_id: '',
                    study_plan_type_id: 0,
                    name: '',
                    entity: '',
                    entity_id: '',
                    entity_name: '',
                    hours: {
                        lectures_classroom_hours: '',
                        practice_classroom_hours: '',
                        independent_classroom_hours: '',
                        lectures_distant_hours: '',
                        practice_distant_hours: '',
                        independent_distant_hours: '',
                        attestation_hours: ''
                    },
                    attestation_type_id: 0,
                    attestation_another_type: '',
                    finalAttestationHours: '',
                    date_from: '',
                    date_to: '',
                    count_days: '',
                    timetable_type_id: '',
                    competences: '',
                    competences_name: '',
                    theme: '',
                    plan: ''
                }
            })
        }
    }
</script>
