<template>
    <div v-if="studyPlan.modules.length">
        <div class="table-responsive mb-4">
            <table class="table table-bordered m-0 text-center">
                <thead class="table-info">
                    <th class="align-middle text-center">№</th>
                    <th class="align-middle">Тема&nbsp;лекции</th>
                    <th class="align-middle">Содержание лекционных занятий (выражается в&nbsp;понятиях и&nbsp;проблемах). Тезисный план лекции по&nbsp;теме</th>
                    <th class="align-middle px-0"></th>
                </thead>

                <tbody>
                    <template v-for="(studyModule, studyModuleIndex) in studyPlan.modules">
                        <tr>
                            <td class="text-center">{{ studyModuleIndex + 1 }}</td>
                            <td colspan="2">{{ studyModule.name }}</td>
                            <td class="px-0"></td>
                        </tr>

                        <template v-for="(studyTheme, studyThemeIndex) in studyModule.study_themes">
                            <tr>
                                <td class="text-center">{{ studyModuleIndex + 1 }}.{{ studyThemeIndex + 1 }}</td>
                                <td colspan="2">{{ studyTheme.name }}</td>
                                <td class="px-0"></td>
                            </tr>

                            <tr v-for="group in teachingMaterialGroupsCollection" :key="`${studyTheme.id}` + `${group.id}`">
                                <td colspan="3">
                                    <div>{{ group.name }}</div>
                                    <div class="list-group">
                                        <div class="list-group-item p-1 d-flex align-items-center" v-for="teachingMaterial in getFilesByGroupId(studyTheme.teaching_materials, group.id)">
                                            <a :href="storageUrl + teachingMaterial.path" :key="teachingMaterial.id" class="btn btn-link text-decoration-none" download="">
                                                {{ teachingMaterial.original_name }}
                                            </a>
                                            <button type="button" class="btn text-danger ml-auto" @click="deleteFile(studyTheme.teaching_materials, teachingMaterial.id)" title="Удалить">
                                                <i class="bi bi-trash-fill"></i>
                                            </button>
                                        </div>
                                    </div>
                                </td>
                                <td class="align-bottom text-center px-0">
                                    <button type="button" class="btn text-primary" @click="addMaterials(studyTheme, 'theme', group.id)" title="Добавление графического, текстового, презентационного файла">
                                        <i class="bi bi-plus-circle h4 m-0"></i>
                                    </button>
                                </td>
                            </tr>

                            <tr v-for="(lecture, lectureIndex) in studyTheme.lectures">
                                <td class="text-center">{{ studyModuleIndex + 1 }}.{{ studyThemeIndex + 1 }}.{{ lectureIndex + 1 }}</td>
                                <td>{{ lecture.theme }}</td>
                                <td>
                                    <div>{{ lecture.plan }}</div>
                                    <div class="list-group">
                                        <div class="list-group-item p-1 d-flex align-items-center" v-for="teachingMaterial in lecture.teaching_materials">
                                            <a :href="storageUrl + teachingMaterial.path" :key="teachingMaterial.id" class="btn btn-link text-decoration-none" download="">
                                                {{ teachingMaterial.original_name }}
                                            </a>
                                            <button type="button" class="btn text-danger ml-auto" @click="deleteFile(lecture.teaching_materials, teachingMaterial.id)" title="Удалить">
                                                <i class="bi bi-trash-fill"></i>
                                            </button>
                                        </div>
                                    </div>
                                </td>
                                <td class="align-bottom text-center px0">
                                    <button type="button" class="btn text-primary" @click="addMaterials(lecture, 'lecture')" title="Добавление графического, текстового, презентационного файла">
                                        <i class="bi bi-plus-circle h4 m-0"></i>
                                    </button>
                                </td>
                            </tr>
                        </template>
                    </template>
                </tbody>
            </table>
        </div>

        <h5>Задания к итоговой аттестации</h5>

        <input type="hidden" :class="{'is-invalid': isInvalidField('teaching_materials')}" name="teaching_materials" v-model.number="studyPlan.finalAttestation.teaching_materials.length">
        <div class="invalid-feedback mt-2" role="alert">
            <div v-for="error in validateErrors.teaching_materials"><strong>{{ error }}</strong></div>
        </div>

        <div class="list-group">
            <div class="list-group-item p-1 d-flex align-items-center" v-for="teachingMaterial in studyPlan.finalAttestation.teaching_materials">
                <a :href="storageUrl + teachingMaterial.path" :key="teachingMaterial.id" class="btn btn-link text-decoration-none" download="">
                    {{ teachingMaterial.original_name }}
                </a>
                <button type="button" class="btn text-danger ml-auto" @click="deleteFile(studyPlan.finalAttestation.teaching_materials, teachingMaterial.id)" title="Удалить">
                    <i class="bi bi-trash-fill"></i>
                </button>
            </div>
        </div>
        <button type="button" class="btn btn-primary my-3" @click="addMaterials(studyPlan.finalAttestation, 'final_attestation')">
            Добавить задание
            <i class="bi bi-plus-circle ml-1"></i>
        </button>

        <div id="popup_files" class="modal fade" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <form>
                            <input type="hidden" name="entity_id" v-model="modal.entity_id">
                            <input type="hidden" name="entity_name" v-model="modal.entity_name">
                            <input type="hidden" name="teaching_material_group_id" v-model="modal.teaching_material_group_id">

                            <div class="input-group mb-0">
                                <div class="custom-file">
                                    <input type="file" :class="[{'is-invalid': isInvalidField('files')}, 'form-control-file', 'custom-file-input']" id="files" name="files[]" multiple accept=".pdf,.ppt,.pptx,.doc,.docx,.zip,.rar,.jpeg,.jpg,.png,.bmp">
                                    <label class="custom-file-label" for="files">Выберите файл (.pdf)</label>
                                </div>

                                <div class="invalid-feedback" role="alert">
                                    <div v-for="error in validateErrors.files"><strong>{{ error }}</strong></div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" @click="uploadFiles">Добавить</button>
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Закрыть</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="h5" v-else>
        Для заполнения раздела необходимо заполнить раздел
        <a :href="contentPageUrl">"Содержание"</a>
    </div>
</template>

<script>
    import validator from "./mixins/validator"

    export default {
        props: [
            'studyPlanCollection',
            'teachingMaterialGroupsCollection',
            'finalAttestation',
            'storageUrl',
            'teachingMaterialsUrl',
            'contentPageUrl',
            'errors'
        ],
        mixins: [validator],
        data() {
            return {
                studyPlan: {
                    modules: this.studyPlanCollection,
                    finalAttestation: this.finalAttestation
                },
                modal: {
                    entity: '',
                    entity_id: '',
                    entity_name: '',
                    teaching_material_group_id: ''
                }
            }
        },
        methods: {
            addMaterials(entity, entity_name, teaching_material_group_id = null) {
                this.modal.entity = entity
                this.modal.entity_id = entity.id
                this.modal.entity_name = entity_name
                this.modal.teaching_material_group_id = teaching_material_group_id
                $("#popup_files").modal('show')
            },
            uploadFiles() {
                let fd = new FormData($("#popup_files form").get(0))

                axios.post(this.teachingMaterialsUrl, fd)
                    .then(({data}) => {
                        this.modal.entity.teaching_materials.push(...data)

                        $("#popup_files").modal('hide')
                        location.reload()
                    })
                    .catch(error => {
                        console.log(error);
                        this.validateErrors = error.response.data.errors
                    })
            },
            deleteFile(files, id) {
                if (confirm('Удалить файл?')) {
                    axios.delete(`${this.teachingMaterialsUrl}/${id}`)
                        .then(({status}) => {
                            if (status == 200) {
                                let i = _.findIndex(files, ['id', id])
                                files.splice(i, 1)
                                location.reload()
                            }
                        })
                        .catch(error => {
                            console.log(error);
                        })
                }
            },
            getFilesByGroupId(materials, groupId) {
                return materials.filter(item => item.teaching_material_group_id == groupId)
            }
        },
        mounted() {
            $('body').on("hidden.bs.modal", () => {
                this.validateErrors = {}

                this.modal = {
                    entity: '',
                    entity_id: '',
                    entity_name: '',
                    teaching_material_group_id: ''
                }

                $("#files").val("")
            })
        }
    }
</script>
