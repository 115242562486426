<template>
    <div>
        <input type="hidden" :class="{'is-invalid': isInvalidField('attestations_listeners')}" name="attestations_listeners" v-model="attestationsListenersFilled">

        <h5>Аттестация слушателей по программам повышения квалификации</h5>

        <div class="table-responsive">
            <table class="table table-bordered m-0 text-center">
                <thead class="table-info">
                    <tr>
                        <th class="align-middle" rowspan="2">Формы&nbsp;контроля</th>
                        <th class="align-middle" rowspan="2">Модуль (раздел,&nbsp;дисциплина)</th>
                        <th class="align-middle" colspan="2">Количество&nbsp;(ед.)</th>
                        <th class="align-middle" colspan="2">Формы</th>
                        <th class="align-middle" colspan="2">Критерии</th>
                        <th class="align-middle" colspan="2">Оценка</th>
                        <th class="align-middle px-0" rowspan="2"></th>
                    </tr>
                    <tr>
                        <th class="align-middle">Аттестация</th>
                        <th class="align-middle">Текущий контроль</th>
                        <th class="align-middle">Аттестация</th>
                        <th class="align-middle">Текущий контроль</th>
                        <th class="align-middle">Аттестация</th>
                        <th class="align-middle">Текущий контроль</th>
                        <th class="align-middle">Аттестация</th>
                        <th class="align-middle">Текущий контроль</th>
                    </tr>
                </thead>

                <tbody>
                    <template v-if="existModuleAttestations">
                        <tr v-for="(valuationMaterial, valuationMaterialIndex) in valuationMaterials.modules" :key="valuationMaterial.id">
                            <td :rowspan="valuationMaterials.modules.length" v-if="valuationMaterialIndex == 0">
                                <div class="mb-2">1. Аттестация (промежуточная)</div>
                                <div>2. Текущий контроль (по&nbsp;освоению лекционного материала)</div>
                            </td>

                            <template v-if="haveAtteatation(valuationMaterial.attestation_type_id)">
                                <td class="align-middle">{{ valuationMaterial.name }}</td>
                                <td class="align-middle">{{ valuationMaterial.attestation_listener.attestation_info.count_attestation }}</td>
                                <td class="align-middle">{{ valuationMaterial.attestation_listener.attestation_info.count_current_control }}</td>
                                <td class="align-middle">{{ valuationMaterial.attestation_listener.attestation_info.forms_attestation }}</td>
                                <td class="align-middle">{{ valuationMaterial.attestation_listener.attestation_info.forms_current_control }}</td>
                                <td class="align-middle">{{ valuationMaterial.attestation_listener.attestation_info.criteries_attestation }}</td>
                                <td class="align-middle">{{ valuationMaterial.attestation_listener.attestation_info.criteries_current_control }}</td>
                                <td class="align-middle">{{ getValuationSystemsName(valuationMaterial.attestation_listener.attestation_info.estimate_attestation) }}</td>
                                <td class="align-middle">{{ getValuationSystemsName(valuationMaterial.attestation_listener.attestation_info.estimate_current_control) }}</td>
                                <td class="align-middle px-0">
                                    <button type="button" class="btn text-muted" @click="editAttestationListener(valuationMaterial.attestation_listener)">
                                        <i class="bi bi-pencil-fill"></i>
                                    </button>
                                </td>
                            </template>
                        </tr>
                    </template>

                    <tr>
                        <td class="align-middle" colspan="2">Итоговая аттестация</td>
                        <td class="align-middle" colspan="2">{{ valuationMaterials.finalAttestation.count }}</td>
                        <td class="align-middle" colspan="2">{{ valuationMaterials.finalAttestation.forms }}</td>
                        <td class="align-middle" colspan="2">{{ valuationMaterials.finalAttestation.criterias }}</td>
                        <td class="align-middle" colspan="2">{{ getValuationSystemsName(valuationMaterials.finalAttestation.value_system_id) }}</td>
                        <td class="align-middle px-0">
                            <button type="button" class="btn text-muted" @click="editFinalAttestation">
                                <i class="bi bi-pencil-fill"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="invalid-feedback mt-2" role="alert">
            <div v-for="error in validateErrors.attestations_listeners"><strong>{{ error }}</strong></div>
        </div>

        <!-- Modal Listener -->
        <div class="modal fade" id="popup_listener" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <form>
                        <input type="hidden" name="_method" value="PUT">
                        <input type="hidden" name="id" v-model="modal.id">
                        <input type="hidden" name="study_theme_id" v-model="modal.parent_id" v-if="modal.isNew">

                        <h5 class="mb-2">Количество (ед.)</h5>
                        <div class="form-group">
                            <label>Аттестация</label>
                            <input name="count_attestation" :class="[{'is-invalid': isInvalidField('count_attestation')}, 'form-control']" type="number" min="0" v-model.number="modal.attestation_info.count_attestation">
                            <div class="invalid-feedback" role="alert">
                                <div v-for="error in validateErrors.plcount_attestationan"><strong>{{ error }}</strong></div>
                            </div>
                        </div>

                        <div class="form-group mb-4">
                            <label>Текущий контроль</label>
                            <input name="count_current_control" :class="[{'is-invalid': isInvalidField('count_current_control')}, 'form-control']" type="number" min="0" v-model.number="modal.attestation_info.count_current_control">
                            <div class="invalid-feedback" role="alert">
                                <div v-for="error in validateErrors.count_current_control"><strong>{{ error }}</strong></div>
                            </div>
                        </div>

                        <h5 class="mb-2">Формы</h5>
                        <div class="form-group">
                            <label>Аттестация</label>
                            <textarea name="forms_attestation" :class="[{'is-invalid': isInvalidField('forms_attestation')}, 'form-control']" rows="3" v-model="modal.attestation_info.forms_attestation"></textarea>
                            <div class="invalid-feedback" role="alert">
                                <div v-for="error in validateErrors.forms_attestation"><strong>{{ error }}</strong></div>
                            </div>
                        </div>

                        <div class="form-group">
                            <label>Текущий контроль</label>
                            <textarea name="forms_current_control" :class="[{'is-invalid': isInvalidField('forms_current_control')}, 'form-control']" rows="3" v-model="modal.attestation_info.forms_current_control"></textarea>
                            <div class="invalid-feedback" role="alert">
                                <div v-for="error in validateErrors.forms_current_control"><strong>{{ error }}</strong></div>
                            </div>
                        </div>

                        <h5 class="mb-2">Критерии</h5>
                        <div class="form-group">
                            <label>Аттестация</label>
                            <textarea name="criteries_attestation" :class="[{'is-invalid': isInvalidField('criteries_attestation')}, 'form-control']" rows="3" v-model="modal.attestation_info.criteries_attestation"></textarea>
                            <div class="invalid-feedback" role="alert">
                                <div v-for="error in validateErrors.criteries_attestation"><strong>{{ error }}</strong></div>
                            </div>
                        </div>

                        <div class="form-group">
                            <label>Текущий контроль</label>
                            <textarea name="criteries_current_control" :class="[{'is-invalid': isInvalidField('criteries_current_control')}, 'form-control']" rows="3" v-model="modal.attestation_info.criteries_current_control"></textarea>
                            <div class="invalid-feedback" role="alert">
                                <div v-for="error in validateErrors.criteries_current_control"><strong>{{ error }}</strong></div>
                            </div>
                        </div>

                        <h5 class="mb-2">Оценка</h5>
                        <div class="form-group">
                            <label>Аттестация</label>
                            <select id="estimate_attestation" name="estimate_attestation" :class="[{'is-invalid': isInvalidField('estimate_attestation')}, 'form-control']" v-model="modal.attestation_info.estimate_attestation">
                                <option value="0" disabled>Не выбрано</option>
                                <option v-for="valuationSystem in valuationSystems" :key="valuationSystem.id" :value="valuationSystem.id">{{ valuationSystem.name }}</option>
                            </select>
                            <div class="invalid-feedback" role="alert">
                                <div v-for="error in validateErrors.estimate_attestation"><strong>{{ error }}</strong></div>
                            </div>
                        </div>

                        <div class="form-group mb-0">
                            <label>Текущий контроль</label>
                            <select id="estimate_current_control" name="estimate_current_control" :class="[{'is-invalid': isInvalidField('estimate_current_control')}, 'form-control']" v-model="modal.attestation_info.estimate_current_control">
                                <option value="0" disabled>Не выбрано</option>
                                <option v-for="valuationSystem in valuationSystems" :key="valuationSystem.id" :value="valuationSystem.id">{{ valuationSystem.name }}</option>
                            </select>
                            <div class="invalid-feedback" role="alert">
                                <div v-for="error in validateErrors.estimate_current_control"><strong>{{ error }}</strong></div>
                            </div>
                        </div>
                    </form>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" @click="updateAttestationListener">Сохранить</button>
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Закрыть</button>
                </div>
                </div>
            </div>
        </div>
        <!-- End Modal Listener -->

        <!-- Modal Attestation -->
        <div class="modal fade" id="popup_attestation" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">

                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <div class="modal-body">
                        <form>
                            <input type="hidden" name="_method" value="PUT">

                            <div class="form-group">
                                <label>Количество (ед.)</label>
                                <input name="count" :class="[{'is-invalid': isInvalidField('count')}, 'form-control']" type="number" min="0" v-model.number="modal.finalAttestation.count">
                                <div class="invalid-feedback" role="alert">
                                    <div v-for="error in validateErrors.count"><strong>{{ error }}</strong></div>
                                </div>
                            </div>

                            <div class="form-group">
                                <label>Формы</label>
                                <textarea name="forms" :class="[{'is-invalid': isInvalidField('forms')}, 'form-control']" rows="3" v-model="modal.finalAttestation.forms"></textarea>
                                <div class="invalid-feedback" role="alert">
                                    <div v-for="error in validateErrors.forms"><strong>{{ error }}</strong></div>
                                </div>
                            </div>

                            <div class="form-group">
                                <label>Критерии</label>
                                <textarea name="criterias" :class="[{'is-invalid': isInvalidField('criterias')}, 'form-control']" v-model="modal.finalAttestation.criterias"></textarea>
                                <div class="invalid-feedback" role="alert">
                                    <div v-for="error in validateErrors.criterias"><strong>{{ error }}</strong></div>
                                </div>
                            </div>

                            <div class="form-group mb-0">
                                <label>Оценка</label>
                                <select name="value_system_id" :class="[{'is-invalid': isInvalidField('value_system_id')}, 'form-control']" v-model="modal.finalAttestation.value_system_id">
                                    <option value="0" disabled>Не выбрано</option>
                                    <option v-for="valuationSystem in valuationSystems" :key="valuationSystem.id" :value="valuationSystem.id">{{ valuationSystem.name }}</option>
                                </select>
                                <div class="invalid-feedback" role="alert">
                                    <div v-for="error in validateErrors.value_system_id"><strong>{{ error }}</strong></div>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" @click="updateFinalAttestation">Обновить</button>
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Закрыть</button>
                    </div>

                </div>
            </div>
        </div>
        <!-- End Modal Attestation -->
    </div>
</template>

<script>
    import validator from "./mixins/validator"

    export default {
        props: [
            'studyPlanCollection',
            'finalAttestation',
            'valuationSystems',
            'finalAttestationUrl',
            'attestationListenersUrl',
            'errors'
        ],
        mixins: [validator],
        computed: {
            attestationsListenersFilled() {
                return this.existModuleAttestations
                ? this.valuationMaterials.modules.length
                    && _.every(this.valuationMaterials.modules, item => _.every(item.attestation_listener.attestation_info, attestation_infoItem => attestation_infoItem !== null))
                    && _.every(_.omit(this.valuationMaterials.finalAttestation, ['hours']), item => item !== null)
                : _.every(_.omit(this.valuationMaterials.finalAttestation, ['hours']), item => item !== null)
            },
            existModuleAttestations() {
                return _.some(this.valuationMaterials.modules, item => this.haveAtteatation(item.attestation_type_id))
            }
        },
        data() {
            return {
                valuationMaterials: {
                    modules: this.studyPlanCollection,
                    finalAttestation: this.finalAttestation
                },
                modal: {
                    id: '',
                    study_plan_id: '',
                    attestation_info: {
                        count_attestation: '',
                        count_current_control: '',
                        forms_attestation: '',
                        forms_current_control: '',
                        criteries_attestation: '',
                        criteries_current_control: '',
                        estimate_attestation: 0,
                        estimate_current_control: 0
                    },
                    finalAttestation: ''
                }
            }
        },
        methods: {
            editAttestationListener(item) {
                this.modal = _.merge(this.modal, _.cloneDeep(item))
                this.modal.study_plan_id = item.study_plan_id
                $('#popup_listener').modal('show')
            },
            updateAttestationListener() {
                this.validateErrors = {}

                let fd = new FormData($("#popup_listener form").get(0))

                axios.post(`${this.attestationListenersUrl}/${this.modal.id}`, fd)
                    .then(() => {
                        let currentModule = this.valuationMaterials.modules.find(item => item.id == this.modal.study_plan_id)
                        _.merge(currentModule.attestation_listener, this.modal)

                        $('#popup_listener').modal('hide')
                    })
                    .catch(error => {
                        console.log(error);
                        this.validateErrors = error.response.data.errors
                    })
            },
            editFinalAttestation() {
                this.modal.finalAttestation = _.cloneDeep(this.valuationMaterials.finalAttestation)
                if (this.modal.finalAttestation.value_system_id === null) {
                    this.modal.finalAttestation.value_system_id = 0
                }

                $('#popup_attestation').modal('show')
            },
            updateFinalAttestation() {
                this.validateErrors = {}

                let fd = new FormData($("#popup_attestation form").get(0))

                axios.post(`${this.finalAttestationUrl}/${this.modal.finalAttestation.id}`, fd)
                    .then(({data}) => {
                        if (data) {
                            _.merge(this.valuationMaterials.finalAttestation, this.modal.finalAttestation)
                            $('#popup_attestation').modal('hide')
                        }
                    })
                    .catch(error => {
                        this.validateErrors = error.response.data.errors
                    })
            },
            getValuationSystemsName(id) {
                return id > 0 ? this.valuationSystems.find(v => v.id == id).name : 'Не указано'
            },
            haveAtteatation(id) {
                return id != 2
            }
        },
        mounted() {
            $('body').on("hidden.bs.modal", () => {
                this.validateErrors = {}

                this.modal = {
                    id: '',
                    study_plan_id: '',
                    attestation_info: {
                        count_attestation: '',
                        count_current_control: '',
                        forms_attestation: '',
                        forms_current_control: '',
                        criteries_attestation: '',
                        criteries_current_control: '',
                        estimate_attestation: 0,
                        estimate_current_control: 0
                    },
                    finalAttestation: ''
                }
            })
        }
    }
</script>
