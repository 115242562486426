export default {
    methods: {
        addPractice(moduleId, themeId, studyFormId) {
            this.modal.root_id = moduleId
            this.modal.parent_id = themeId
            this.modal.study_form_id = studyFormId
            $("#popup_practice").modal("show")
        },
        editPractice(moduleId, item) {
            this.modal = _.merge(this.modal, _.cloneDeep(item))
            this.modal.root_id = moduleId
            this.modal.parent_id = item.study_theme_id
            this.modal.isNew = false
            $('#popup_practice').modal('show')
        },
        savePractice() {
            this.validateErrors = {}

            let fd = new FormData($("#popup_practice form").get(0))

            axios.post(this.practiceUrl, fd)
                .then(({data}) => {
                    let currentModule = this.studyPlan.modules.find(item => item.id == this.modal.root_id)
                    let currentTheme = currentModule.study_themes.find(item => item.id == this.modal.parent_id)
                    this.modal.id = data.id
                    this.modal.study_theme_id = data.study_theme_id
                    currentTheme.practices.push(_.cloneDeep(this.modal))

                    $('#popup_practice').modal('hide')
                })
                .catch(error => {
                    console.log(error);
                    this.validateErrors = error.response.data.errors
                })
        },
        updatePractice() {
            this.validateErrors = {}

            let fd = new FormData($("#popup_practice form").get(0))

            axios.post(`${this.practiceUrl}/${this.modal.id}`, fd)
                .then(() => {
                    let currentModule = this.studyPlan.modules.find(item => item.id == this.modal.root_id)
                    let currentTheme = currentModule.study_themes.find(item => item.id == this.modal.parent_id)
                    let currentPractice = currentTheme.practices.find(item => item.id == this.modal.id)

                    _.merge(currentPractice, this.modal)

                    $('#popup_practice').modal('hide')
                })
                .catch(error => {
                    console.log(error);
                    this.validateErrors = error.response.data.errors
                })
        },
        deletePractice(theme, practiceId) {
            if (confirm('Удалить практическое занятие?')) {
                axios.delete(`${this.practiceUrl}/${practiceId}`)
                    .then(({status}) => {
                        if (status == 200) {
                            let i = _.findIndex(theme.practices, ['id', practiceId])
                            theme.practices.splice(i, 1);
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    })
            }
        }
    }
}
