export default {
    methods: {
        addCourse() {
            axios.post(this.courseUrl)
                .then(({status, data}) => {
                    if (status = 201) {
                        data.groups = []
                        this.studyPlan.courses.push(data)
                    }
                })
                .catch(error => {
                    console.log(error);
                })
        },
        deleteCourse(courseId) {
            if (confirm('Удалить курс?')) {
                axios.delete(`${this.courseUrl}/${courseId}`)
                    .then(({status}) => {
                        if (status == 200) {
                            let i = _.findIndex(this.studyPlan.courses, ['id', courseId])
                            this.studyPlan.courses.splice(i, 1);
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    })
            }
        }
    }
}
