export default {
    methods: {
        editFinalAttestation(item) {
            this.modal.finalAttestationHours = item.hours
            this.modal.id = item.id
            $('#popup_attestation').modal('show')
        },
        saveFinalAttestation() {
            this.validateErrors = {}

            let fd = new FormData($("#popup_attestation form").get(0))

            axios.post(`${this.finalAttestationUrl}/${this.modal.id}`, fd)
                .then(({data}) => {
                    if (data) {
                        this.studyPlan.finalAttestation.hours = this.modal.finalAttestationHours
                        $('#popup_attestation').modal('hide')
                    }
                })
                .catch(error => {
                    this.validateErrors = error.response.data.errors
                })
        }
    }
}
